import React, { useRef, useState } from 'react'
import Loader from '../Loader'
import BottomFooter from '../BottomFooter'
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'
import { styleObjectGetBorderColor, styleObjectGetColor } from '../../common/StyleSeter'
import { useSelector } from 'react-redux'

function P2Ptransfer() {
    const DD = useSelector((e: any) => e.domainDetails);
    const [isLoadingMain, setIsLoadingMain] = useState(false)
    const [copiedDone, setCopiedDone] = useState(false)
    const navigate = useNavigate()


    const inputStyle = {
        '& label.Mui-focused': styleObjectGetColor(DD?.colorSchema),
        '& .MuiInput-underline:after': {
            borderBottomColor: styleObjectGetBorderColor(DD?.colorSchema),
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: styleObjectGetBorderColor(DD?.colorSchema),
            },
            '&:hover fieldset': {
                borderColor: styleObjectGetBorderColor(DD?.colorSchema),
            },
            '&.Mui-focused fieldset': {
                borderColor: styleObjectGetBorderColor(DD?.colorSchema),
            },
        },
    }
    const textInputRef = React.useRef(null);

    const copyToClipboard = async (textInputRef: any) => {
        if (textInputRef.current) {
            try {
                await navigator.clipboard.writeText(textInputRef.current.value);
                // Optionally, provide feedback to the user
                // alert('Text copied to clipboard: ' + textInputRef.current.value);
                setCopiedDone(true)
                setTimeout(() => {
                    setCopiedDone(false)
                }, 1000);
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
            }
        }
    };




    return (
        <>
            <div className='full-wrap bottomfooter leagues'>
                {isLoadingMain ?
                    <Loader />
                    :
                    <>
                        <div className="balance-label position-relative">
                            <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => navigate('/')}>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                                </svg>
                            </button>
                            P2P Transfer
                        </div>

                        <div className="p-3 pt-4 inner-sidebar-content">
                            <form className="p2transfer-form login-panel">
                                <label className="form-label">My Wallet ID</label>
                                <div className="bg-white rounded p-3 position-relative d-flex align-items-center wallet-form-control">
                                    <input disabled type="text" className="form-control" readOnly value="65894ec054f04335621d73b6" ref={textInputRef} />
                                    <button type="button" className="theme-btn px-2 ms-2 btn btn-primary" onClick={() => copyToClipboard(textInputRef)}>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"></path>
                                        </svg>
                                    </button>
                                    {copiedDone && <div className="overlay-wallet">Copied</div>}
                                </div>
                                <div className="mt-4">
                                    <label className="form-label">Transfer To</label>
                                    <div className="bg-white rounded p-3 ">
                                        <div className="mb-sm-4 mb-3 form-floating">
                                            <input name="recipient" placeholder="" type="text" id="floatingInput" className="form-control" />
                                            <label htmlFor="floatingInput">Recipient wallet ID</label>

                                        </div>


                                        <div className="pbu-floating">
                                            <div className="mb-sm-4 mb-3 form-floating">
                                                <input name="amount" placeholder="" type="number" id="floatingInput" className="form-control" />
                                                <span className="position-absolute top-0 d-flex align-items-center h-100 text-center px-3 border-right border-end-1 border-1">PBU</span>
                                                <label htmlFor="floatingInput">Transfer Amount</label>
                                            </div>
                                        </div>

                                        <div className="mb-sm-4 mb-3 form-floating">
                                            <input name="remark" placeholder="" type="text" id="floatingInput" className="form-control" />
                                            <label htmlFor="floatingInput">Remark</label>
                                        </div>


                                        <div className="gx-3 mt-3 row lower-btn">
                                            <div className="col-6"><button type="button" className="bg-white border-dark text-dark w-100 h-100 fs-5 btn btn-primary">Clear</button></div>
                                            <div className="col-6"><button type="submit" className="theme-btn w-100 btn btn-primary">Transfer</button></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </>
                }
            </div>
            <BottomFooter />
        </>


    )
}

export default P2Ptransfer