import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import { USER_API } from '../common/common'
import { Logout } from '../common/Funcation'
import { getApi, notifyError, notifyMessage, postApi } from '../service'

const cookies = new Cookies()

const StakePopup = (props: any) => {
    const { setOpenSetting, getStackForPerent } = props
    const [manageStack, setManageStack] = useState<any>()
    const [loading, setLoading] = useState(true)
    const isAuthenticated = useSelector((e: any) => e.isAuthenticated);

    const [edit, setEdit] = useState(false)

    useEffect(() => {

        getStack(isAuthenticated?.token)

        return () => {

        }
    }, [])

    const getStack = async (token: string) => {

        let data = {
            api: USER_API.GET_STACK,
            value: {},
            token: token ? token : undefined
        }

        await getApi(data).then(function (response) {
            let resData = response.data?.data?.stackInfo
            if (response.data?.data?.stackInfo?.stack?.length > 0) {

                setManageStack(resData)
                setInputs(resData?.stack)
            } else {
                resData.stack = [10, 20, 30, 40, 50, 60, 70, 80]
                setManageStack(resData)
            }

            // setOpenSetting(!OpenSetting)
            // setSearchData(response.data.data)
            setLoading(false)

        }).catch(err => {

            notifyError(err.response.data.message)
            if (err.response.data.statusCode === 401) {
                Logout()
                // navigate('/login')
            }
        })
    }
    const editClick = () => {
        setEdit(!edit)
    }
    const handleCheckBoxChange = (e: any) => {

        const { name, checked } = e.target

        setManageStack({
            ...manageStack,
            [name]: checked
        })
    }
    const handleStackChange = (e: any, id: any) => {
        const { name, value } = e.target
        let stackCopy = manageStack.stack
        stackCopy[name] = parseInt(value) ? parseInt(value) : 0

        setManageStack({
            ...manageStack,
            stack: stackCopy
        })
    }

    const manageDefault = (e: any) => {
        const { name, value } = e.target
        setManageStack({
            ...manageStack,
            [name]: value
        })
    }



    // const [input1, setInput1] = useState(0);
    // const [input2, setInput2] = useState(0);
    // const [input3, setInput3] = useState(0);
    // const [input4, setInput4] = useState(0);

    const [inputs, setInputs] = useState<any>();
    const [selectedInput, setSelectedInput] = useState(0);


    const handleButtonClick = (number: any) => {
        setInputs((prevInputs: any) => {
            const updatedInputs = [...prevInputs];
            if (updatedInputs[selectedInput] < 10000) {
                updatedInputs[selectedInput] = updatedInputs[selectedInput] * 10 + number;
                if (updatedInputs[selectedInput] >= 10000) {
                    updatedInputs[selectedInput] %= 10000;
                }
            }
            return updatedInputs;
        });
    };

    const handleRemoveDigit = () => {
        setInputs((prevInputs: any) => {
            const updatedInputs = [...prevInputs];
            updatedInputs[selectedInput] = Math.floor(updatedInputs[selectedInput] / 10);
            return updatedInputs;
        });
    };

    const updateSetting = async () => {

        let data = {
            api: USER_API.UPDATE_STACK,
            value: {
                "defaultStack": manageStack.defaultStack,
                "stack": inputs,
                "highLightsOdds": manageStack.highLightsOdds,
                "acceptFancyOdds": manageStack.acceptFancyOdds,
                "acceptBookmakerOdds": manageStack.acceptBookmakerOdds
            },
        }

        await postApi(data).then(function (response) {
            getStackForPerent()
            setOpenSetting(false)
            notifyMessage('update stack success')
            // setSearchData(response.data.data)

        }).catch(err => {

            notifyError(err.response.data.message)
            setOpenSetting(false)
            if (err.response.data.statusCode === 401) {

                // Logout()
                // navigate('/login')
            }
        })
        // setOpenModal(true)
    }



    return (
        <>
            <div className="market-depth-modal default-stake">
                <div className="market-title">
                    <h4>Default stake setting</h4>
                    <button type="button" className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary" onClick={() => setOpenSetting(false)}>
                        <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                        </svg>
                    </button>
                </div>
                <div className="market-depth-body">
                    <form className="">
                        <div className="bg-darklight p-2">
                            <div className="gx-3 row">

                                {inputs?.length > 0 && inputs?.slice(0, 4).map((item: any, i: any) =>
                                    <div className="col-3" key={i}>
                                        <div className={`${selectedInput === i ? 'active' : ''} defultinput`} >
                                            <input inputMode="none" maxLength={5} type="number" className="form-control" value={item} onClick={() => setSelectedInput(i)} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="betting-numbers  mb-2 px-sm-3 px-2">
                            <div className="d-flex flex-wrap bet-numeric w-100 mb-sm-4 mb-2">
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(1)} >1</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(2)} >2</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(3)} >3</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(4)} >4</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(5)} >5</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(6)} >6</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(7)} >7</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(8)} >8</button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(9)} >9</button>
                                <button type="button" className="btn btn btn-primary" ></button>
                                <button type="button" className="btn btn btn-primary" onClick={() => handleButtonClick(0)} >0</button>
                                <button type="button" className="btn cross-bet btn btn-primary" onClick={() => handleRemoveDigit()}>
                                    <img src="data:image/svg+xml,<svg width=&quot;18&quot; height=&quot;12&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot;><path d=&quot;M14.52 8.6c.07.06.1.13.1.2 0 .06-.03.13-.1.2l-.74.75c-.05.05-.12.07-.21.07-.1 0-.16-.02-.21-.07l-2.67-2.63-2.67 2.63c-.05.05-.12.07-.22.07-.09 0-.16-.02-.2-.07L6.85 9c-.07-.07-.11-.14-.11-.2 0-.07.04-.14.1-.2L9.54 6 6.82 3.4c-.14-.13-.14-.26 0-.4l.77-.75c.05-.05.12-.07.21-.07.1 0 .17.02.22.07l2.67 2.6 2.67-2.6c.05-.05.12-.07.21-.07.1 0 .16.02.21.07l.77.75c.15.14.15.27 0 .4L11.85 6l2.67 2.6zM17.54.47c.3.3.46.67.46 1.12v8.73c0 .45-.16.85-.47 1.18-.32.33-.7.49-1.15.49H5.91a2.52 2.52 0 0 1-2-.99l-.04-.03L0 6 3.87.95C4.36.32 5.04 0 5.9 0h10.47c.45 0 .83.16 1.16.48zm-.66 9.93V1.52c0-.35-.17-.52-.5-.52H5.91c-.47 0-.85.19-1.13.56L1.41 6l3.37 4.44c.3.37.68.56 1.13.56h10.47c.33 0 .5-.2.5-.59z&quot; fill=&quot;%231E1E1E&quot; fill-rule=&quot;evenodd&quot; /></svg>" alt="" />
                                </button>
                            </div>
                            <button type="button" className="bg-yellow footer-btn w-100 fs-5 py-2 fw-bold btn btn-primary" onClick={() => updateSetting()}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>

        </>

    )
}

export default StakePopup
