/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import BottomFooter from '../../components/BottomFooter'
import Loader from '../../components/Loader'
import Slider from 'react-slick'
import { getApiWithData, notifyError, postApi } from '../../service'
import { USER_API } from '../../common/common'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Logout, getImageUrl } from '../../common/Funcation'
import Cookies from 'universal-cookie'
import { isMobile } from 'react-device-detect'
import { styleObjectGetBG } from '../../common/StyleSeter'
import { useSelector } from 'react-redux'

const cookies = new Cookies()

function Casino() {
    const [isLoadingMain, setIsLoadingMain] = useState(false)
    const [activeCategory, setActiveCategory] = useState<any>('Popular')
    const [mainFilter, setMainFilter] = useState<any>('Catalog')
    const [secondFilter, setSecondFilter] = useState<any>('')
    const [searchActive, setSearchActive] = useState(false)
    const [casnioData, setCasnioData] = useState<any>()
    const DD = useSelector((e: any) => e.domainDetails);

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const category = params.get('category');
    console.log("category", category)
    const navigate = useNavigate()



    const whoitsforsettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        variableWidth: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: false,
                    variableWidth: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false,
                    dots: false,
                    // variableWidth: false,
                }
            },
        ]
    };



    useEffect(() => {
        if (category?.length) {
            setActiveCategory(category)
            getFirstTimeCasinoData(category)
        }
        else {
            getFirstTimeCasinoData(activeCategory)
        }
    }, [category])




    const getFirstTimeCasinoData = async (name: any) => {
        setActiveCategory(name)
        let data = {
            api: USER_API.GET_CASINO,
            value: {
                gameType: name,
                filter: mainFilter,
                subName: secondFilter || '',
                domain: window.location.hostname
            },
        }
        await getApiWithData(data).then(function (response) {
            console.log(response);
            setCasnioData(response?.data?.data)
            // dispatch({ type: 'SET_HOME_DATA', payload: response.data.data })
            // setLoadingImages(response.data.data.dashboardImagesInfo.map((item: any) => item._id));

        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                navigate('/login')
            }
        })

    }


    const handleCasinoChange = async (name: any, subfilter: any) => {
        // Parse the current query parameters
        const params = new URLSearchParams(search);
        // Remove the 'category' parameter
        params.delete('category');
        // Update the URL without triggering a full page reload
        const newUrl = `${window.location.pathname}`;
        window.history.replaceState({}, '', newUrl);

        setActiveCategory(name)

        let data = {
            api: USER_API.GET_CASINO,
            value: {
                gameType: name,
                filter: mainFilter,
                subName: subfilter || '',
                domain: window.location.hostname

            },
        }
        await getApiWithData(data).then(function (response) {
            console.log(response);
            setCasnioData(response?.data?.data)
            // dispatch({ type: 'SET_HOME_DATA', payload: response.data.data })
            // setLoadingImages(response.data.data.dashboardImagesInfo.map((item: any) => item._id));

        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                navigate('/login')
            }
        })
    }


    const handleMainFilter = async (name: any) => {
        setMainFilter(name)
        let data = {
            api: USER_API.GET_CASINO,
            value: {
                gameType: activeCategory,
                filter: name,
                subName: secondFilter || '',
                domain: window.location.hostname
            },
        }
        await getApiWithData(data).then(function (response) {
            console.log(response);
            setCasnioData(response?.data?.data)
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                navigate('/login')
            }
        })
    }


    const handleSecondFilter = async (name: any) => {
        setSecondFilter(name)
        let data = {
            api: USER_API.GET_CASINO,
            value: {
                gameType: activeCategory,
                filter: mainFilter,
                subName: name,
                domain: window.location.hostname
            },
        }
        await getApiWithData(data).then(function (response) {
            console.log(response);
            setCasnioData(response?.data?.data)
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                navigate('/login')
            }
        })
    }







    const getCasinoLink = async (item: any) => {
        if (cookies.get('skyTokenFront')) {
            let data = {
                api: USER_API.CASINO_LOGIN,
                value: {
                    id: item._id,
                    isMobileLogin: isMobile ? true : false,
                    domain: window.location.hostname === "localhost" ? 'taka365.win' : window.location.hostname
                },
                token: cookies.get('skyTokenFront') ? cookies.get('skyTokenFront') : '',
            }

            await postApi(data).then(function (response) {
                window.open(response?.data?.data?.url, '_blank');
            }).catch(err => {
                console.log(err);
                notifyError(err?.response?.data?.message)

                if (err.response.data.statusCode === 401) {
                    Logout()
                    // notifyError('Pin unsuccess')
                    // navigate('/login')
                }
            })
        } else {
            // if (isMobile) { navigate('/login') }
            if (isMobile) { window.location.pathname = '/login' }
            notifyError('please login first')
        }
    }



    return (
        <>

            <div className='full-wrap bottomfooter'>
                {isLoadingMain ? <div className='loader_top'>
                    <Loader />
                </div>

                    :

                    <div className='casino'>
                        <div className='games-slot'>
                            <Slider  {...whoitsforsettings}>
                                <div className={` games-slot-item ${activeCategory === 'Popular' ? "active" : ""} `} onClick={() => {
                                    setSecondFilter('')
                                    handleCasinoChange('Popular', '');
                                }}>
                                    <div>
                                        <h6>Popular</h6>
                                        {activeCategory === 'Popular' ?
                                            <img src="./images/casino-icon/hover-popular.svg" alt="" />
                                            :
                                            <img src="./images/casino-icon/popular.svg" alt="" />
                                        }
                                    </div>
                                </div>
                                <div className={` games-slot-item ${activeCategory === 'Live' ? "active" : ""} `} onClick={() => {
                                    setSecondFilter('SEXY');
                                    handleCasinoChange('Live', 'SEXY');
                                }}>
                                    <div>
                                        <h6>Live</h6>
                                        {activeCategory === 'Live' ?
                                            <img src="./images/casino-icon/hover-live.svg" alt="" />
                                            :
                                            <img src="./images/casino-icon/icon-live.svg" alt="" />
                                        }
                                    </div>
                                </div>
                                <div className={` games-slot-item ${activeCategory === 'Table' ? "active" : ""} `} onClick={() => {
                                    handleCasinoChange('Table', 'KINGMAKER');
                                    setSecondFilter('KINGMAKER')
                                }}>
                                    <div>
                                        <h6>Table</h6>
                                        {activeCategory === 'Table' ?
                                            <img src="./images/casino-icon/hover-table.svg" alt="" />
                                            :
                                            <img src="./images/casino-icon/icon-table.svg" alt="" />
                                        }
                                    </div>
                                </div>
                                <div className={` games-slot-item ${activeCategory === 'Slot' ? "active" : ""} `} onClick={() => {
                                    handleCasinoChange('Slot', 'PT');
                                    setSecondFilter('PT')
                                }}>
                                    <div>
                                        <h6>Slot</h6>
                                        {activeCategory === 'Slot' ?
                                            <img src="./images/casino-icon/hover-slot.svg" alt="" />
                                            :
                                            <img src="./images/casino-icon/icon-slot.svg" alt="" />
                                        }
                                    </div>
                                </div>
                                <div className={` games-slot-item ${activeCategory === 'Fishing' ? "active" : ""} `} onClick={() => {
                                    handleCasinoChange('Fishing', 'JILI');
                                    setSecondFilter('JILI')
                                }}>
                                    <div>
                                        <h6>Fishing</h6>
                                        {activeCategory === 'Fishing' ?
                                            <img src="./images/casino-icon/hover-fishing.svg" alt="" />
                                            :
                                            <img src="./images/casino-icon/icon-fishing.svg" alt="" />
                                        }
                                    </div>
                                </div>
                                <div className={`games-slot-item ${activeCategory === 'Egame' ? "active" : ""} `} onClick={() => {
                                    handleCasinoChange('Egame', 'PP');
                                    setSecondFilter('PP')
                                }}>
                                    <div>
                                        <h6>Egame</h6>
                                        {activeCategory === 'Egame' ?
                                            <img src="./images/casino-icon/hover-egame.svg" alt="" />
                                            :
                                            <img src="./images/casino-icon/egame.svg" alt="" />
                                        }
                                    </div>
                                </div>
                            </Slider>
                        </div>


                        {/* second filter  */}
                        {activeCategory !== 'Popular' &&
                            <div className="d-flex align-items-center flex-wrap subcasino">
                                {['Live', 'exchange'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('SEXY')} className={`${secondFilter === 'SEXY' ? 'active' : ''}`}  style={secondFilter === 'SEXY' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>SEXY</span>
                                }
                                {['Live', 'exchange'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('EVO')} className={`${secondFilter === 'EVO' ? 'active' : ''}`} style={secondFilter === 'EVO' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>EVO</span>
                                }
                                {['Live', 'Slot'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('PT')} className={`${secondFilter === 'PT' ? 'active' : ''}`} style={secondFilter === 'PT' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>PT</span>
                                }
                                {['Live', 'Slot', 'Egame'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('PP')} className={`${secondFilter === 'PP' ? 'active' : ''}`} style={secondFilter === 'PP' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>PP</span>
                                }
                                {['Live', 'exchange'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('VENUS')} className={`${secondFilter === 'VENUS' ? 'active' : ''}`} style={secondFilter === 'VENUS' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>VENUS</span>
                                }
                                {['Table', 'Slot'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('KINGMAKER')} className={`${secondFilter === 'KINGMAKER' ? 'active' : ''}`} style={secondFilter === 'KINGMAKER' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>KINGMAKER</span>
                                }
                                {['bookmaker', 'Slot'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('DRAGOONSOFT')} className={`${secondFilter === 'DRAGOONSOFT' ? 'active' : ''}`} style={secondFilter === 'DRAGOONSOFT' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>DRAGOONSOFT</span>
                                }
                                {['bookmaker', 'Slot', 'Egame'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('JDB')} className={`${secondFilter === 'JDB' ? 'active' : ''}`} style={secondFilter === 'JDB' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>JDB</span>
                                }
                                {['bookmaker', 'exchange'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('VEKINGMAKERNUS')} className={`${secondFilter === 'VEKINGMAKERNUS' ? 'active' : ''}`} style={secondFilter === 'VEKINGMAKERNUS' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>VEKINGMAKERNUS</span>
                                }
                                {['Table', 'Slot', 'Fishing'].includes(activeCategory) &&
                                    <span onClick={() => handleSecondFilter('JILI')} className={`${secondFilter === 'JILI' ? 'active' : ''}`} style={secondFilter === 'JILI' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}>JILI  </span>
                                }
                            </div>
                        }


                        <div className='main-casino-wrapper'>


                            {/* search seciton  */}
                            {searchActive &&
                                <div className="search-games-sec">
                                    <form className="">
                                        <div className="position-relative">
                                            <input placeholder="Search platform, games, and more......" type="text" className="form-control" value="" />
                                            <button type="button" className="bg-transparent border-0 text-dark position-absolute end-0 top-0 pt-0 fs-4 btn btn-primary" onClick={() => setSearchActive(false)}>
                                                <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="search-listing mt-sm-3 ">
                                            <ul className="p-0 m-0">
                                                <div className="w-100 mb-3 match-list">

                                                    {false ?
                                                        <div className="no-event-outer">
                                                            <div className="no-event">
                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" color="lightgrey" className="mb-2" height="55" width="55" xmlns="http://www.w3.org/2000/svg" style={{ color: 'lightgrey;' }}>
                                                                    <path d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"></path>
                                                                </svg>
                                                                No Event
                                                            </div>
                                                        </div>

                                                        :

                                                        <ul className="p-0">
                                                            <li>The Greatest Cards Show</li>
                                                            <li>Triple Card Poker</li>
                                                        </ul>
                                                    }
                                                </div>

                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            }


                            {/* main filter  and search button*/}
                            <div className="casinofilter d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <span className={`${mainFilter === 'Catalog' ? 'active' : ''}`} style={mainFilter === 'Catalog' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}} onClick={() => handleMainFilter('Catalog')} >Catalog</span>
                                    {(activeCategory === 'Popular' || activeCategory === 'Live') &&
                                        <span className={`${mainFilter === 'Latest' ? 'active' : ''}`} style={mainFilter === 'Latest' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}} onClick={() => handleMainFilter('Latest')}>Latest</span>
                                    }
                                    <span className={`${mainFilter === 'A-Z' ? 'active' : ''}`} style={mainFilter === 'A-Z' ? styleObjectGetBG(DD?.colorSchema, false, true) : {}}  onClick={() => handleMainFilter('A-Z')}>A-Z</span>
                                </div>
                                <button type="button" className="bg-transparent border-0 text-dark btn btn-primary" onClick={() => setSearchActive(true)}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                    </svg>
                                </button>
                            </div>


                            <div className='games-wrapper casino-all-games active'>
                                {(activeCategory === 'Popular' || activeCategory === 'Live') ?
                                    <>
                                        {casnioData?.catalog.reverse()?.map((item: any, i: any) => {
                                            return (
                                                <>
                                                    <div className='games-card-inner bg-white' key={i}>
                                                        <h2 style={styleObjectGetBG(DD?.colorSchema,false, true)}>{item ? item  : 'Other'}</h2>
                                                        <div className='row'>
                                                            {casnioData?.casinoType?.filter((_: any) => _.catalog === item).map((catalogItem: any, index: any) => {
                                                                return (
                                                                    <div className='game-card position-relative' key={index}>
                                                                        <span className=" lazy-load-image-background opacity lazy-load-image-loaded">
                                                                            <img src={getImageUrl(catalogItem?.image)} alt="game"  onClick={() => getCasinoLink(catalogItem)} />
                                                                        </span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>
                                    :

                                    <div className='games-card-inner bg-white'>
                                        <div className='row'>
                                            {casnioData?.casinoType?.map((item: any, i: any) => {
                                                return (
                                                    <div className='game-card position-relative' key={i}>
                                                        <span className=" lazy-load-image-background opacity lazy-load-image-loaded">
                                                            <img src={getImageUrl(item?.image)} alt="game" onClick={() => getCasinoLink(item)}/>
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>




                            <Footer />

                        </div>
                    </div>
                }
            </div >
            <BottomFooter />



        </>
    )
}

export default Casino