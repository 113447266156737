/* eslint-disable jsx-a11y/anchor-is-valid */
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import React, { useState } from 'react'
import BottomFooter from '../BottomFooter'
import Loader from '../Loader'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick';

const whoitsforsettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // variableWidth: true,
  arrows: false,
};

function ProfitLoss(_props) {
  const [isLoadingMain, setIsLoadingMain] = useState(false)
  const [activeCategory, setActiveCategory] = useState('Exchange')
  const [during, setDuring] = useState(false)
  const [datePickerPopup, setDatePickerPopup] = useState(false)
  const navigate = useNavigate()


  const [state, setState] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    color: '#9cb1bd',
    key: 'selection'
  }]);


  return (
    <>
      {console.log("state[0]", state[0])}
      <div className='full-wrap bottomfooter leagues'>
        {isLoadingMain ?
          <div className='loader_top'>
            <Loader />
          </div>
          :
          <>
            <div className="balance-label position-relative">
              <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => navigate('/')}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                </svg>
              </button>
              Profit & Loss
            </div>

            <div className="pt-0 inner-sidebar-content">
              <div className='sports-tab-panel py-2 px-0'>
                <Slider {...whoitsforsettings}>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Exchange' ? "active" : ""} `} onClick={() => setActiveCategory('Exchange')}>
                    <div>
                      <h3>Exchange</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Bookmaker' ? "active" : ""} `} onClick={() => setActiveCategory('Bookmaker')}>
                    <div>
                      <h3>Bookmaker</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Fancybet' ? "active" : ""} `} onClick={() => setActiveCategory('Fancybet')}>
                    <div>
                      <h3>Fancybet</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Sportsbook' ? "active" : ""} `} onClick={() => setActiveCategory('Sportsbook')}>
                    <div>
                      <h3>Sportsbook</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Tie' ? "active" : ""} `} onClick={() => setActiveCategory('Tie')}>
                    <div>
                      <h3>Tie</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Toss' ? "active" : ""} `} onClick={() => setActiveCategory('Toss')}>
                    <div>
                      <h3>Toss</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Casino' ? "active" : ""} `} onClick={() => setActiveCategory('Casino')}>
                    <div>
                      <h3>Casino</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'Parlay' ? "active" : ""} `} onClick={() => setActiveCategory('Parlay')}>
                    <div>
                      <h3>Parlay</h3>
                    </div>
                  </div>
                </Slider>
              </div>

              <div className="pl-filter">
                <div className="select-container bet-history-dropdown">
                  <div className="pl-filter-first p-0">
                    <div className="pl-filter-first-time">
                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M712 304c0 4.4-3.6 8-8 8h-56c-4.4 0-8-3.6-8-8v-48H384v48c0 4.4-3.6 8-8 8h-56c-4.4 0-8-3.6-8-8v-48H184v136h656V256H712v48z"></path>
                        <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zm0-448H184V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136z"></path>
                      </svg>
                      <span className="text-white truncate" onClick={() => setDatePickerPopup(true)}>
                        {state[0]?.endDate === null ?

                          `${new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(state[0]?.startDate)} - ${new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(state[0]?.startDate)}`
                          :
                          `${new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(state[0]?.startDate)} - ${new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(state[0]?.endDate)}`
                        }
                      </span>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={() => setDuring(true)}>...</button>
                    <button type="button" className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div >
      <BottomFooter />



      {
        during &&
        <>
          <div className="market-depth-modal">
            <div className="market-title">
              <h4>During</h4>
              <button type="button" onClick={() => setDuring(false)} className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary">
                <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="p-2 calendar-button"><button type="button" className="btn btn-primary">Today</button><button type="button" className="btn btn-primary">From Yesterday</button><button type="button" className="btn btn-primary">Last 7 Days</button></div>
            </div>
          </div>
          <div className="overlay" onClick={() => setDuring(false)}></div>
        </>
      }


      {datePickerPopup &&
        <>
          <div className="market-depth-modal">
            <div className="market-title">
              <h4>During</h4>
              <button type="button" onClick={() => setDatePickerPopup(false)} className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary">
                <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <DateRange
                editableDateInputs={false}
                ranges={state}
                maxDate={new Date()}
                onChange={item => setState([item.selection])}
              />
            </div>
          </div>
          <div className="overlay" onClick={() => setDatePickerPopup(false)}></div>
        </>
      }

    </>



  )
}

export default ProfitLoss