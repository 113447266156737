import React, { useEffect } from 'react';
import LoaderImage from "../assets/images/loaderajaxbet.gif"

const Loader = () => {

    useEffect(() => {
        document.querySelector('body')!.classList.add('no-scroll');
        return () => {
            document.querySelector('body')!.classList.remove('no-scroll');
        }
    }, [])


    return (
        <>
            {/* <div className="sd_loader">
            <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#04ac80" stroke="none">
                    <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51" />
                </path>
            </svg>
        </div> */}
            {/* <div className="main-loader">
                <ul className="loading">
                    <li>
                        <img src={LoaderImage} />
                    </li>
                    <li>Loading...</li>
                </ul>
            </div> */}



            <div id="loading" className="loading" style={{ display: 'flex' }}>
                <div className="loading-box">
                    <div className="loading-spinner">
                    </div>
                </div>
            </div>
        </>




    )

}
export default Loader;