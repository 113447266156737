/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import BottomFooter from '../BottomFooter'
import Loader from '../Loader'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick';

const whoitsforsettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // variableWidth: true,
  arrows: false,
};


function MyProfile(_props: any) {
  const [isLoadingMain, setIsLoadingMain] = useState(false)
  const [during, setDuring] = useState(false)
  const navigate = useNavigate()


  return (
    <>
      <div className='full-wrap bottomfooter leagues'>
        {isLoadingMain ?
          <div className='loader_top'>
            <Loader />
          </div>
          :
          <>
            <div className="balance-label position-relative">
              <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => navigate('/')}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                </svg>
              </button>
              My Profile
            </div>

            <div className="p-sm-3 p-2 inner-sidebar-content">
              <table className="align-middle bg-white table">
                <tbody>
                  <tr>
                    <td width="30%">User Name</td>
                    <td width="70%" colSpan={2}>pratik</td>
                  </tr>
                  <tr>
                    <td width="30%">Email</td>
                    <td width="70%" colSpan={2}>pratik@gmail.com</td>
                  </tr>
                  <tr>
                    <td width="30%">Password</td>
                    <td width="40%">********</td>
                    <td width="30%"><button type="button" className="theme-btn py-1 px-3 fs-6 btn btn-primary">Edit</button></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </>
        }
      </div>
      <BottomFooter />



      {during && <div className="market-depth-modal">
        <div className="market-title">
          <h4>During</h4>
          <button type="button" onClick={() => setDuring(false)} className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary">
            <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
            </svg>
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="p-2 calendar-button"><button type="button" className="btn btn-primary">Today</button><button type="button" className="btn btn-primary">From Yesterday</button><button type="button" className="btn btn-primary">Last 7 Days</button></div>
        </div>
      </div>
      }
    </>



  )
}

export default MyProfile