import React, { useEffect, useState } from 'react'

let dropdown = []
function MarketDepthPopup({ depthInfo }) {

    useEffect(() => {
        dropdown = depthInfo?.matchInfo?.name ? depthInfo?.matchInfo?.name.split('v').map(item => ({ label: item.trim(), value: item.trim() })) : [];
    }, [depthInfo?.matchInfo])




    const [activeDD, setActiveDD] = useState(false)
    const [selectedDropdown, setSelectedDropdown] = useState(depthInfo?.matchInfo?.name?.split('v')[0])

    const handleDDselect = (value) => {
        setSelectedDropdown(value)
        setActiveDD(false)
    }


    return (
        <section className="market-data list-area rounded-t-lg bg-white overflow-hidden">
            <div className="main-filter-ul d-flex justify-content-center items-content-center h-40px bg-black-300 text-center">
                <div className="event-market-name d-flex-1 whitespace-pre-wrap leading-tight text-clip text-13">
                    <span className="font-bold">{depthInfo?.matchInfo?.name?.split('v')[0]}</span><span className="mx-4px">vs</span><span className="font-bold">{depthInfo?.matchInfo?.name?.split('v')[1]}</span>
                    <div className="icon-triangle-black-600 inline-block align-baseline mx-4px"></div>
                    <span>Match {depthInfo?.matchInfo?.gameStatus}</span>
                </div>
            </div>


            <div className='pl-filter'>
                <div className='select-container bet-history-dropdown'>
                    <div className="dropdown">
                        <button type="button" onClick={() => setActiveDD(!activeDD)} id="dropdown-basic-button" aria-expanded="false" className={`${activeDD ? 'show' : ''} dropdown-toggle btn btn-primary`}>{selectedDropdown}</button>
                        <div x-placement="bottom-start" aria-labelledby="dropdown-basic-button" className={`${activeDD ? 'show' : ''} dropdown-menu`} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-start"
                        >

                            {dropdown.filter((_) => _.value !== selectedDropdown).map((item, i) => {
                                return (
                                    <>
                                        <a className="dropdown-item" onClick={() => handleDDselect(item.value)}>
                                            {item.value}
                                        </a>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>





            <div className="row second d-flex border-t border-b border-black-300 my-2">
                <div className="item odds border-r border-black-300 p-2 d-flex-1">
                    <div className="label text-10">Total matched</div>
                    <div className="content font-bold leading-tight">33505463.21</div>
                </div>
                <div className="item stake border-r border-black-300 p-2 d-flex-1">
                    <div className="label text-10">Selection Volume</div>
                    <div className="content font-bold leading-tight">27354430.56</div>
                </div>
                <div className="item profit p-2 d-flex-1">
                    <div className="label text-10">Last price</div>
                    <div className="content font-bold leading-tight"><span>1.16</span></div>
                </div>
            </div>
            <div className=" market-data-info d-flex justify-content-center items-content-center h-45px text-white bg-black-900">
                Price, Exchange Available and Traded
            </div>
            <div>
                <div className="market-data-btn d-flex justify-content-between p-2">
                    <div className="d-flex justify-content-center items-content-center w-20 h-8 px-0 bg-back-300 rounded cyan-bg">
                        <span className="icon-arrow-left text-12 text-back-600 scale-75">
                            <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 1024 1024" class="icon" version="1.1"><path  d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#000000" /></svg>
                        </span>
                        <span className="text-13 ml-1 mr-2 font-normal ">to Back</span></div>
                    <div className="d-flex justify-content-center items-content-center w-20 h-8 px-0 bg-lay-300 rounded pink-bg">
                        <span className="text-13 ml-2 mr-1 font-normal ">to Lay</span>
                        <span className="icon-arrow-right rotate-180 text-12 text-lay-600 scale-75 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 1024 1024" class="icon" version="1.1"><path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#000000" /></svg>
                        </span>
                    </div>
                </div>
                <div className="market-data-list w-full mt-2 overflow-x-scroll">
                    <div className="d-flex upper-div">
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.01</div>
                                <div className="text-11">109085.65</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.02</div>
                                <div className="text-11">60518.82</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.03</div>
                                <div className="text-11">56476</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.04</div>
                                <div className="text-11">55775.72</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.05</div>
                                <div className="text-11">57327.61</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.06</div>
                                <div className="text-11">53760.72</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.07</div>
                                <div className="text-11">53798.6</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.08</div>
                                <div className="text-11">54139.99</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.09</div>
                                <div className="text-11">42630.46</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.1</div>
                                <div className="text-11">54781.29</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.11</div>
                                <div className="text-11">57845.79</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.12</div>
                                <div className="text-11">111880</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.13</div>
                                <div className="text-11">138603.25</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.14</div>
                                <div className="text-11">69921.23</div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex d-flex-col justify-content-center items-content-center w-80px h-12 bg-back-100 label text-10 border-t border-t-black-300 border-r border-white">
                                <div className="text-13 font-bold">1.15</div>
                                <div className="text-11">2383.82</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.16</div>
                                <div className="text-11">54645.67</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.17</div>
                                <div className="text-11">40373.15</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.18</div>
                                <div className="text-11">61351.62</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.19</div>
                                <div className="text-11">38900.08</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.2</div>
                                <div className="text-11">17717.41</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.21</div>
                                <div className="text-11">7836.19</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.22</div>
                                <div className="text-11">568.47</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.23</div>
                                <div className="text-11">854.35</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.24</div>
                                <div className="text-11">777.86</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.25</div>
                                <div className="text-11">463.24</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.26</div>
                                <div className="text-11">23.23</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.27</div>
                                <div className="text-11">84.08</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.28</div>
                                <div className="text-11">476.44</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.29</div>
                                <div className="text-11">77.93</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.3</div>
                                <div className="text-11">299.41</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.31</div>
                                <div className="text-11">89.84</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.32</div>
                                <div className="text-11">103.07</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.33</div>
                                <div className="text-11">225.9</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.34</div>
                                <div className="text-11">8.27</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.35</div>
                                <div className="text-11">48.4</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.36</div>
                                <div className="text-11">25.46</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.37</div>
                                <div className="text-11">4.1</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.38</div>
                                <div className="text-11">131.86</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.39</div>
                                <div className="text-11">5.69</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.4</div>
                                <div className="text-11">533.14</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.41</div>
                                <div className="text-11">4.13</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.42</div>
                                <div className="text-11">22.39</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.44</div>
                                <div className="text-11">161.64</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.45</div>
                                <div className="text-11">96.48</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.46</div>
                                <div className="text-11">0.87</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.47</div>
                                <div className="text-11">23.76</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.48</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.49</div>
                                <div className="text-11">4.94</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.5</div>
                                <div className="text-11">139.86</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.52</div>
                                <div className="text-11">41.35</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.54</div>
                                <div className="text-11">68.3</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.55</div>
                                <div className="text-11">4.94</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.56</div>
                                <div className="text-11">63.37</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.58</div>
                                <div className="text-11">1.05</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.59</div>
                                <div className="text-11">18</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.6</div>
                                <div className="text-11">88.77</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.61</div>
                                <div className="text-11">0.03</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.64</div>
                                <div className="text-11">56.26</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.65</div>
                                <div className="text-11">129.09</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.66</div>
                                <div className="text-11">44.17</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.68</div>
                                <div className="text-11">109.65</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.7</div>
                                <div className="text-11">106.21</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.72</div>
                                <div className="text-11">58.32</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.74</div>
                                <div className="text-11">1.52</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.75</div>
                                <div className="text-11">149.08</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.79</div>
                                <div className="text-11">1.27</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.8</div>
                                <div className="text-11">11.88</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.81</div>
                                <div className="text-11">48.57</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.85</div>
                                <div className="text-11">9.52</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.86</div>
                                <div className="text-11">3.52</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.87</div>
                                <div className="text-11">3.04</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.9</div>
                                <div className="text-11">121.47</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.95</div>
                                <div className="text-11">56.54</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.98</div>
                                <div className="text-11">4.56</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1.99</div>
                                <div className="text-11">242.35</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2</div>
                                <div className="text-11">140.46</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.08</div>
                                <div className="text-11">15.41</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.1</div>
                                <div className="text-11">4.94</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.2</div>
                                <div className="text-11">4.1</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.22</div>
                                <div className="text-11">25.7</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.24</div>
                                <div className="text-11">45.77</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.28</div>
                                <div className="text-11">14.59</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.3</div>
                                <div className="text-11">944.22</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.34</div>
                                <div className="text-11">35.06</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.38</div>
                                <div className="text-11">6.19</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.4</div>
                                <div className="text-11">72.26</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.44</div>
                                <div className="text-11">1.52</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.48</div>
                                <div className="text-11">7.4</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.5</div>
                                <div className="text-11">24.79</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.52</div>
                                <div className="text-11">32.14</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.58</div>
                                <div className="text-11">7.4</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.6</div>
                                <div className="text-11">6.34</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.62</div>
                                <div className="text-11">4.1</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.64</div>
                                <div className="text-11">571.37</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.68</div>
                                <div className="text-11">7.4</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.74</div>
                                <div className="text-11">0.84</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.76</div>
                                <div className="text-11">4.1</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.78</div>
                                <div className="text-11">7.4</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.8</div>
                                <div className="text-11">9.66</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.86</div>
                                <div className="text-11">4.1</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.9</div>
                                <div className="text-11">16.67</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">2.98</div>
                                <div className="text-11">4.94</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3</div>
                                <div className="text-11">21.86</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3.05</div>
                                <div className="text-11">3.17</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3.25</div>
                                <div className="text-11">4.1</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3.4</div>
                                <div className="text-11">21.32</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3.5</div>
                                <div className="text-11">6.67</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3.8</div>
                                <div className="text-11">0.84</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3.85</div>
                                <div className="text-11">950.66</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">3.95</div>
                                <div className="text-11">31.69</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">4</div>
                                <div className="text-11">154.43</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">4.1</div>
                                <div className="text-11">3.17</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">4.3</div>
                                <div className="text-11">114.23</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">4.5</div>
                                <div className="text-11">63.37</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">4.7</div>
                                <div className="text-11">0.43</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">4.8</div>
                                <div className="text-11">0.84</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">5</div>
                                <div className="text-11">130.7</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">5.2</div>
                                <div className="text-11">24.62</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">5.7</div>
                                <div className="text-11">0.16</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">6</div>
                                <div className="text-11">110.74</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">7</div>
                                <div className="text-11">26.96</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">8</div>
                                <div className="text-11">32.6</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">8.4</div>
                                <div className="text-11">16.41</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">9</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">9.8</div>
                                <div className="text-11">4.75</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">10</div>
                                <div className="text-11">49.79</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">10.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">11</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">11.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">12</div>
                                <div className="text-11">11.42</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">12.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">13</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">13.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">14</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">14.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">15</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">15.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">16</div>
                                <div className="text-11">9.51</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">16.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">17</div>
                                <div className="text-11">8.14</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">17.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">18</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">18.5</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">19</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">19.5</div>
                                <div className="text-11">6.59</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">20</div>
                                <div className="text-11">46.67</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">21</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">22</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">23</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">24</div>
                                <div className="text-11">6.51</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">25</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">26</div>
                                <div className="text-11">3.17</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">27</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">28</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">29</div>
                                <div className="text-11">5.69</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">30</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">32</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">34</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">38</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">40</div>
                                <div className="text-11">3.17</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">42</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">44</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">46</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">48</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">55</div>
                                <div className="text-11">0.79</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">65</div>
                                <div className="text-11">4.75</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">100</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">110</div>
                                <div className="text-11">3.17</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">200</div>
                                <div className="text-11">2.03</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">220</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">290</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">300</div>
                                <div className="text-11">1.2</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">340</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">390</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">440</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">490</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">500</div>
                                <div className="text-11">0.79</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">540</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">590</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">640</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">690</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">700</div>
                                <div className="text-11">0.79</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">740</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">790</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">840</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">890</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">900</div>
                                <div className="text-11">1.58</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">940</div>
                                <div className="text-11">7.92</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">960</div>
                                <div className="text-11">1.9</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">990</div>
                                <div className="text-11">118.82</div>
                            </div>
                        </div>
                        <div className="availableToLay">
                            <div className="d-flex availableToLayItem d-flex-col justify-content-center items-content-center w-80px h-12 bg-lay-100 label text-10 border-t border-t-black-300 border-r border-r-white">
                                <div className="text-13 font-bold">1000</div>
                                <div className="text-11">9.19</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex lowwer-div">
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">357570.19</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">1060789.07</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">827238.1</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">1261912.12</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">474902.72</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">292170.62</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">369936.99</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">258091.74</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">1015160.01</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">956181.12</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">361623.43</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">277893.86</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">238204.07</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">84637.44</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">47665.7</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">27044.83</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">83720.22</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">72321.24</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">182494.79</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">290638.8</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">211003.08</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">214118.75</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">807359.27</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">529581.96</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">463233.84</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">330134.23</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">344536.42</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">346459.89</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">609992.03</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">610563.51</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">536182.84</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">348078.51</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">290000.86</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">203904.12</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">356013.99</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">480416.85</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">152045.18</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">210623.33</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">291382.2</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">225507.13</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">249331.5</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">331580.08</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">284170.15</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">116189.85</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">53383.42</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">103986.09</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">144100.21</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">225128.13</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">205193.86</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">238369.13</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">275024.37</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">533878.53</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">196541.44</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">179198.12</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">76080.48</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">129625.01</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">157400.44</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">203960.11</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">84329.85</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">139334.05</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">55190.32</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">15555.42</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">104397.1</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">373645.66</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">252408.22</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">33855.97</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">5427.07</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">14966.33</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">32056.78</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">51038.82</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">61292.54</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">78542.66</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">84501.52</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">38365.8</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">23952.98</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">30221.83</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">18704.58</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">12858.36</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">34675.92</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">16716.58</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">6383.93</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">27174.15</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">12592.73</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">20979.18</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">31206.01</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">58333.05</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">1036.24</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">4094.77</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">10009.34</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">3406.42</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">4484.01</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">4921.93</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300">63.42</div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center items-content-center w-80px h-7 text-11 border-r border-b border-black-300"></div>
                        </div>
                    </div>
                </div>
                <div className="slight-delay text-12 text-black-700 pl-3 mt-2">The information on this page may be slightly delayed.</div>
            </div>
        </section>
    )
}

export default MarketDepthPopup