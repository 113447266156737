import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import BottomFooter from '../../components/BottomFooter'
import { USER_API } from '../../common/common'
import { getApiWithData, postApi } from '../../service'
import { useNavigate } from 'react-router-dom'
import { styleObjectGetBG, styleObjectGetColor } from '../../common/StyleSeter'
import { useSelector } from 'react-redux'

function Leagues() {
    const navigate = useNavigate()
    const [isLoadingMain, setIsLoadingMain] = useState(false)
    const [categoryActive, setcategoryActive] = useState('all')
    const [secondLevel, setSecondLevel] = useState(false)
    const [leagueNameList, setLeagueNameList] = useState([])
    const [setselectedLeague, setSetselectedLeague] = useState('')
    const [leagueMatchList, setleagueMatchList] = useState([])
    const DD = useSelector((e: any) => e.domainDetails);

    useEffect(() => {
        fetchLeagueNameList('')
        return () => {

        }
    }, [])


    const fetchLeagueNameList = async (name: any) => {
        let data = {
            api: USER_API.LEAGUE_NAME,
            value: {
                type: name?.length ? name : categoryActive
            },
        }
        await postApi(data).then(function (response) {
            console.log(response);
            setLeagueNameList(response?.data?.data?.sportLeageName)
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                // Logout()
                // navigate('/login')
            }
        })
    }


    const handleSelectLeagueName = (name: any) => {
        setSetselectedLeague(name)
        setSecondLevel(true)
        fetchLeagueMatchList(name)
    }


    const fetchLeagueMatchList = async (leagueName: any) => {
        let data = {
            api: USER_API.SPORT_LISTBY_LEAGUE,
            value: {
                name: leagueName
            },
        }
        await postApi(data).then(function (response) {
            console.log(response);
            setleagueMatchList(response?.data?.data?.sportDetail)
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                // Logout()
                // navigate('/login')
            }
        })
    }

    const handleChangeCategory = (name: any) => {
        setcategoryActive(name)
        fetchLeagueNameList(name)
    }




    return (
        <>
            <div className='full-wrap bottomfooter leagues'>
                {isLoadingMain ? <div className='loader_top'>
                    <Loader />
                </div>

                    :
                    <>

                        {!secondLevel ?
                            <>
                                <div className="game-menu"  >
                                    <ul className="p-0 mb-0">
                                        <li className={`${categoryActive === 'all' ? 'active' : ''}`} style={categoryActive === 'all' ? styleObjectGetBG(DD?.colorSchema) : {}} onClick={() => handleChangeCategory('all')}>
                                            <div>
                                                <svg stroke="currentColor"  fill={categoryActive === 'all' ? styleObjectGetColor(DD?.colorSchema).color : "#ffffff"}  strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M868 160h-92v-40c0-4.4-3.6-8-8-8H256c-4.4 0-8 3.6-8 8v40h-92a44 44 0 0 0-44 44v148c0 81.7 60 149.6 138.2 162C265.6 630.2 359 721.8 476 734.5v105.2H280c-17.7 0-32 14.3-32 32V904c0 4.4 3.6 8 8 8h512c4.4 0 8-3.6 8-8v-32.3c0-17.7-14.3-32-32-32H548V734.5C665 721.8 758.4 630.2 773.8 514 852 501.6 912 433.7 912 352V204a44 44 0 0 0-44-44zM248 439.6c-37.1-11.9-64-46.7-64-87.6V232h64v207.6zM840 352c0 41-26.9 75.8-64 87.6V232h64v120z"></path>
                                                </svg>
                                            </div>
                                            <span style={categoryActive === 'all' ? styleObjectGetColor(DD?.colorSchema) : {}} >
                                            All
                                            </span>
                                        </li>
                                        <li className={`${categoryActive === 'cricket' ? 'active' : ''}`}  style={categoryActive === 'cricket' ? styleObjectGetBG(DD?.colorSchema) : {}} onClick={() => handleChangeCategory('cricket')}>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 93.000000 96.000000" preserveAspectRatio="xMidYMid meet">
                                                    <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="currentColor"   fill={categoryActive === 'cricket' ? styleObjectGetColor(DD?.colorSchema).color : "#ffffff"} >
                                                        <path d="M352 913 c-13 -3 -22 -9 -20 -15 2 -5 116 -147 253 -314 169 -206 251 -300 257 -291 5 6 18 43 29 81 68 219 -58 452 -287 530 -45 15 -173 20 -232 9z" />
                                                        <path d="M208 846 c-10 -7 -18 -16 -18 -20 0 -4 122 -155 271 -337 l271 -330 24 22 23 21 -79 97 c-379 462 -456 555 -465 557 -5 2 -18 -3 -27 -10z" />
                                                        <path d="M80 698 c-40 -71 -54 -138 -48 -239 7 -137 71 -249 185 -325 85 -56 161 -77 263 -71 82 4 174 31 168 48 -6 18 -520 639 -528 639 -5 0 -23 -24 -40 -52z" />
                                                    </g>
                                                </svg>
                                            </div>
                                            <span style={categoryActive === 'cricket' ? styleObjectGetColor(DD?.colorSchema) : {}} >
                                            Cricket
                                            </span>
                                        </li>
                                        <li className={`${categoryActive === 'soccer' ? 'active' : ''}`} style={categoryActive === 'soccer' ? styleObjectGetBG(DD?.colorSchema) : {}} onClick={() => handleChangeCategory('soccer')}>
                                            <div>
                                                <svg stroke="currentColor"   fill={categoryActive === 'soccer' ? styleObjectGetColor(DD?.colorSchema).color : "#ffffff"}  strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M255.03 33.813c-1.834-.007-3.664-.007-5.5.03-6.73.14-13.462.605-20.155 1.344.333.166.544.32.47.438L204.78 75.063l73.907 49.437-.125.188 70.625.28L371 79.282 342.844 52c-15.866-6.796-32.493-11.776-49.47-14.78-12.65-2.24-25.497-3.36-38.343-3.407zM190.907 88.25l-73.656 36.78-13.813 98.407 51.344 33.657 94.345-43.438 14.875-76.5-73.094-48.906zm196.344.344l-21.25 44.5 36.75 72.72 62.063 38.905 11.312-21.282c.225.143.45.403.656.75-.77-4.954-1.71-9.893-2.81-14.782-6.446-28.59-18.59-55.962-35.5-79.97-9.07-12.872-19.526-24.778-31.095-35.5l-20.125-5.342zm-302.656 23c-6.906 8.045-13.257 16.56-18.938 25.5-15.676 24.664-26.44 52.494-31.437 81.312C31.783 232.446 30.714 246.73 31 261l20.25 5.094 33.03-40.5L98.75 122.53l-14.156-10.936zm312.719 112.844l-55.813 44.75-3.47 101.093 39.626 21.126 77.188-49.594 4.406-78.75-.094.157-61.844-38.783zm-140.844 6.406l-94.033 43.312-1.218 76.625 89.155 57.376 68.938-36.437 3.437-101.75-66.28-39.126zm-224.22 49.75c.91 8.436 2.29 16.816 4.156 25.094 6.445 28.59 18.62 55.96 35.532 79.968 3.873 5.5 8.02 10.805 12.374 15.938l-9.374-48.156.124-.032-27.03-68.844-15.782-3.968zm117.188 84.844l-51.532 8.156 10.125 52.094c8.577 7.49 17.707 14.332 27.314 20.437 14.612 9.287 30.332 16.88 46.687 22.594l62.626-13.69-4.344-31.124-90.875-58.47zm302.437.5l-64.22 41.25-42 47.375 4.408 6.156c12.027-5.545 23.57-12.144 34.406-19.72 23.97-16.76 44.604-38.304 60.28-62.97 2.51-3.947 4.87-7.99 7.125-12.092zm-122.78 97.656l-79.94 9.625-25.968 5.655c26.993 4 54.717 3.044 81.313-2.813 9.412-2.072 18.684-4.79 27.75-8.062l-3.156-4.406z"></path>
                                                </svg>
                                            </div>
                                            <span style={categoryActive === 'soccer' ? styleObjectGetColor(DD?.colorSchema) : {}} >
                                            Soccer
                                            </span>
                                        </li>
                                        <li className={`${categoryActive === 'tennis' ? 'active' : ''}`} style={categoryActive === 'tennis' ? styleObjectGetBG(DD?.colorSchema) : {}} onClick={() => handleChangeCategory('tennis')}>
                                            <div>
                                                <svg stroke="currentColor"   fill={categoryActive === 'tennis' ? styleObjectGetColor(DD?.colorSchema).color : "#ffffff"}  strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M246.938 35.188c-25.875 1.07-51.58 6.686-75.5 16.593-26.714 11.065-51.274 27.462-71.72 47.907-20.445 20.446-36.84 45.005-47.906 71.72-9.8 23.662-15.405 49.065-16.562 74.655 74.49-11.465 126.867-33.367 160.656-67.156 33.846-33.845 50.15-80.167 51.03-143.72zm18 0c-.91 66.75-18.61 118.764-56.313 156.468-38.05 38.05-95.324 60.96-173.438 72.625 1 26.133 6.65 52.13 16.657 76.283 11.067 26.715 27.458 51.24 47.906 71.687 20.448 20.448 45.003 36.87 71.72 47.938 24.152 10.005 50.122 15.63 76.25 16.625 11.665-78.115 34.575-135.39 72.624-173.438 37.704-37.704 89.72-55.402 156.47-56.313-1.06-25.916-6.67-51.663-16.595-75.625-11.068-26.715-27.49-51.27-47.94-71.718-20.447-20.45-44.97-36.84-71.686-47.907-23.96-9.927-49.735-15.56-75.656-16.626zm211.187 229.875c-63.202.987-109.34 17.307-143.063 51.03-33.793 33.795-55.662 86.15-67.125 160.657 25.596-1.157 51.026-6.762 74.688-16.563 26.714-11.064 51.242-27.46 71.688-47.906 20.445-20.444 36.842-44.972 47.906-71.686 9.906-23.922 15.522-49.652 16.592-75.53-.228.002-.46-.005-.687-.002z"></path>
                                                </svg>
                                            </div>
                                            <span style={categoryActive === 'tennis' ? styleObjectGetColor(DD?.colorSchema) : {}} >
                                            Tennis
                                            </span>
                                        </li>
                                        {/* <li className={`${categoryActive === 'Virtual' ? 'active' : ''}`} onClick={() => handleChangeCategory('Virtual')}>
                                <div>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M255.03 33.813c-1.834-.007-3.664-.007-5.5.03-6.73.14-13.462.605-20.155 1.344.333.166.544.32.47.438L204.78 75.063l73.907 49.437-.125.188 70.625.28L371 79.282 342.844 52c-15.866-6.796-32.493-11.776-49.47-14.78-12.65-2.24-25.497-3.36-38.343-3.407zM190.907 88.25l-73.656 36.78-13.813 98.407 51.344 33.657 94.345-43.438 14.875-76.5-73.094-48.906zm196.344.344l-21.25 44.5 36.75 72.72 62.063 38.905 11.312-21.282c.225.143.45.403.656.75-.77-4.954-1.71-9.893-2.81-14.782-6.446-28.59-18.59-55.962-35.5-79.97-9.07-12.872-19.526-24.778-31.095-35.5l-20.125-5.342zm-302.656 23c-6.906 8.045-13.257 16.56-18.938 25.5-15.676 24.664-26.44 52.494-31.437 81.312C31.783 232.446 30.714 246.73 31 261l20.25 5.094 33.03-40.5L98.75 122.53l-14.156-10.936zm312.719 112.844l-55.813 44.75-3.47 101.093 39.626 21.126 77.188-49.594 4.406-78.75-.094.157-61.844-38.783zm-140.844 6.406l-94.033 43.312-1.218 76.625 89.155 57.376 68.938-36.437 3.437-101.75-66.28-39.126zm-224.22 49.75c.91 8.436 2.29 16.816 4.156 25.094 6.445 28.59 18.62 55.96 35.532 79.968 3.873 5.5 8.02 10.805 12.374 15.938l-9.374-48.156.124-.032-27.03-68.844-15.782-3.968zm117.188 84.844l-51.532 8.156 10.125 52.094c8.577 7.49 17.707 14.332 27.314 20.437 14.612 9.287 30.332 16.88 46.687 22.594l62.626-13.69-4.344-31.124-90.875-58.47zm302.437.5l-64.22 41.25-42 47.375 4.408 6.156c12.027-5.545 23.57-12.144 34.406-19.72 23.97-16.76 44.604-38.304 60.28-62.97 2.51-3.947 4.87-7.99 7.125-12.092zm-122.78 97.656l-79.94 9.625-25.968 5.655c26.993 4 54.717 3.044 81.313-2.813 9.412-2.072 18.684-4.79 27.75-8.062l-3.156-4.406z"></path>
                                    </svg>
                                </div>
                                Virtual
                            </li> */}
                                    </ul>
                                </div>

                                <div className="d-flex p-3 leage-list main-casino-wrapper">
                                    {!leagueNameList?.filter((_) => _ !== '')?.length ?
                                        <div className="no-event-outer">
                                            <div className="no-event">
                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" color="lightgrey" className="mb-2" height="55" width="55" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"></path>
                                                </svg>
                                                No Event
                                            </div>
                                        </div>
                                        :
                                        <div className="main-list w-100 ">
                                            <h6 className="mb-3">Popular</h6>

                                            {leagueNameList?.filter((_) => _ !== '')?.map((item, i) => {
                                                return (
                                                    <div className="w-100 mb-3 match-list" key={i}>
                                                        <ul className="p-0 m-0" onClick={() => handleSelectLeagueName(item)}>
                                                            <li>
                                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="568.000000pt" height="560.000000pt" viewBox="0 0 568.000000 560.000000" preserveAspectRatio="xMidYMid meet">

                                                                    <g transform="translate(0.000000,560.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                                        <path d="M2539 5535 c-783 -96 -1502 -537 -1938 -1190 -251 -375 -399 -773 -453 -1215 -16 -135 -16 -515 0 -650 59 -485 243 -940 541 -1338 109 -145 351 -394 482 -494 280 -216 626 -393 950 -487 550 -158 1206 -130 1733 74 681 263 1215 763 1519 1420 172 372 248 723 249 1145 0 436 -76 785 -257 1175 -268 577 -743 1052 -1321 1321 -272 126 -541 203 -834 239 -152 18 -518 18 -671 0z m521 -675 c487 -44 919 -247 1273 -598 502 -497 710 -1211 558 -1911 -58 -263 -200 -566 -372 -793 -84 -110 -283 -309 -388 -388 -447 -336 -966 -476 -1516 -409 -450 55 -876 267 -1209 604 -162 164 -289 346 -390 560 -143 302 -197 543 -197 880 0 226 17 360 72 558 137 490 474 935 912 1201 385 234 814 335 1257 296z" />
                                                                        <path d="M2783 4166 c-23 -7 -62 -25 -85 -39 -24 -14 -212 -196 -418 -404 -281 -283 -382 -391 -400 -428 -21 -43 -25 -64 -25 -145 0 -81 4 -103 24 -145 33 -66 100 -131 169 -163 55 -26 63 -27 275 -30 l217 -3 0 -554 c0 -542 0 -556 21 -612 15 -37 38 -73 71 -106 171 -171 454 -124 557 93 l26 55 3 562 3 562 217 3 217 3 59 30 c127 64 189 164 190 305 0 141 0 141 -447 588 -313 312 -402 396 -440 412 -62 27 -176 35 -234 16z" />
                                                                    </g>
                                                                </svg>
                                                                {item}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            </>

                            :

                            <div className="second-level-leage">
                                <div className="balance-label position-relative text-start ps-5">
                                    <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => setSecondLevel(false)}>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                                        </svg>
                                    </button>
                                    {setselectedLeague}
                                </div>
                                <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
                                    <div className="main-list w-100 ">
                                        {leagueMatchList?.map((item: any, i) => {
                                            return (
                                                <div className="w-100 mb-3 match-list" onClick={() => navigate(`/multimarket/${item.gameId}/${item.marketId}`)}>
                                                    <ul className="p-0 m-0">
                                                        <li><span className="d-block">{item?.name?.split(' v ')[0]}</span><span className="d-block">{item?.name?.split(' v ')[1]}</span></li>
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                    </>

                }
            </div>
            <BottomFooter />
        </>
    )
}

export default Leagues