import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
    const navigate = useNavigate()
    const DD = useSelector((e) => e.domainDetails);

    useEffect(() => {
        if(DD){
            window.location.href = "/"
        }else{
            document.body.className += ' pagenotfound';
        }
    }, [DD])



    return (
        <div className='pagenotfound_container'>

            <h2>
                Page Not Found
            </h2>
        </div>
    )
}

export default PageNotFound