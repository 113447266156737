
import { COLOR_OPTION_INTERFACE } from "../pages/Setting/interface"
import { COLOR_OPTION, NEW_COLOR_OPTION } from "./common"
import { getImageUrl } from "./Funcation"

export const getTheme = (THEME: string, flag = false) => {
    let theme = COLOR_OPTION.find((_: COLOR_OPTION_INTERFACE) => _.value === THEME)
    if(flag){
        theme = NEW_COLOR_OPTION.find((_: COLOR_OPTION_INTERFACE) => _.value === THEME)
    }
    return theme
    // COLOR_OPTION
}


export const styleObjectGetBG = (theme: string, isBorder: boolean = false, Color: boolean = false) => {
    let obj: any = {
        background: getTheme(theme)?.backgroundColor,
    }
    if (isBorder) {
        obj.border = `1px solid ${getTheme(theme)?.backgroundColor}`
    }
    if (Color) {
        obj.color = getTheme(theme)?.color
    }
    return obj
}
export const styleObjectGetColor = (theme: string,flag = false, black = false) => {
    let obj: any = {
        color: black ? '#000' : getTheme(theme, flag)?.color,
    }
    return obj
}

export const styleObjectBlackButton = (theme: string, Hover: boolean) => {
    let obj = {
        color: Hover ? getTheme(theme)?.color : getTheme(theme)?.backgroundColor,
        border: `1px solid ${getTheme(theme)?.backgroundColor}`,
        background: Hover ? getTheme(theme)?.backgroundColor : getTheme(theme)?.color
    }
    return obj
}

export const styleObjectWhiteButton = (theme: string, Hover: boolean) => {
    let obj = {
        color: Hover ? getTheme(theme)?.color : 'black',
        // border: `1px solid ${getTheme(theme)?.backgroundColor}`,
        background: Hover ? getTheme(theme)?.backgroundColor : 'white'
    }
    return obj
}

export const styleObjectGetBGIMageLoginImage = (path: string) => {
    let obj = {
        backgroundImage: `url(${getImageUrl(path)})`
    }
    return obj
}
export const styleObjectGetLoginImage = (path: string) => {
    let obj = {
        backgroundImage: `url(${getImageUrl(path)})`
    }
    return obj
}
export const styleObjectGetBGasColor = (theme: string, borderColor = false) => {
    let obj: any = {
        color: getTheme(theme)?.backgroundColor,
    }
    if (borderColor) {
        obj.borderColor = getTheme(theme)?.backgroundColor;
    }
    return obj
}
export const styleObjectGetBorderColor = (theme: string) => {
    let obj = {
        borderColor: getTheme(theme)?.backgroundColor,
    }
    return obj
}