/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Cookies from "universal-cookie";
import { USER_API } from "../common/common";
import {
  styleObjectGetBGasColor,
  styleObjectGetBGIMageLoginImage,
  styleObjectGetBorderColor,
  styleObjectGetColor,
} from "../common/StyleSeter";
import { getApiLink, notifyError, postApi } from "../service";
import instagram from "./../assets/icons/instagram.svg";
import mail from "./../assets/icons/mail.svg";
import skype from "./../assets/icons/skype.svg";
import telegram from "./../assets/icons/telegram.svg";
import whatsapp from "./../assets/icons/whatsapp.svg";
import { getImageUrl } from "../common/Funcation";
const cookies = new Cookies();

const Login = (props: any) => {
  const { setOpenModal, OpenModal } = props;
  const [, updateState] = React.useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DD = useSelector((e: any) => e.domainDetails);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    validationCode: "",
  });

  useEffect(() => {
    if (DD?.isMaintenance === true) {
      window.location.href = "/Maintenance";
    }
  }, [DD]);

  const isCaptcha = process.env.REACT_APP_CAPTCHA;
  const SocialIcon = [
    { iconStatus: true, link: "", image: mail, name: "mail" },
    { iconStatus: true, link: "", image: whatsapp, name: "whatsapp" },
    { iconStatus: true, link: "", image: telegram, name: "telegram" },
    { iconStatus: true, link: "", image: skype, name: "skype" },
    { iconStatus: true, link: "", image: instagram, name: "instagram" },
  ];
  const SocialContact = ["+918888899999", "+918888897779", "+91888884399"];
  const [confirmationCode, setConfirmationCode] = useState(
    Math.floor(1000 + Math.random() * 9000)
  );
  const [isValidationWrong, setIsValidationWrong] = useState(false);
  const [isError, setIsError] = useState({ flag: false, msg: "" });
  const handelInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsValidationWrong(false);
    setFormData({ ...formData, [name]: value });
  };
  const handelSubmit = async (e: any) => {
    e.preventDefault();
    if (
      Validator.current.allValid() &&
      (confirmationCode === parseInt(formData.validationCode) ||
        isCaptcha === "false")
    ) {
      setIsValidationWrong(false);
      const ipDetails = await getApiLink({
        api: "https://ipapi.co/json/?key=wfzfdfQ4cUsaTVURUkj2oF6L51Y4jNE0IM2yE0V2xMyMkxjTsr",
      });
      console.log("call ip:  ipDetails :", ipDetails);

      let data = {
        api: USER_API.LOGIN,
        value: {
          user_name: formData.username,
          password: formData.password,
          domain: window.location.hostname,
          // domain: "skydemo.live",
          city: ipDetails?.data?.city,
          state: ipDetails?.data?.region,
          country: ipDetails?.data?.country_name,
          ISP: ipDetails?.data?.org,
        },
      };
      await postApi(data)
        .then(function (loginData) {
          if (loginData) {
            //  notifyMessage(loginData.data.message)

            console.log("::::::::;", loginData);
            let HeaderData = {
              ...loginData.data.data.roleAccess,
              user_name: loginData.data.data.user_name,
              remaining_balance: loginData.data.data?.balance,
              ...loginData.data.data,
            };
             dispatch({ type: 'HEADER_DETAILS', payload: HeaderData })
            cookies.set("skyTokenFront", loginData.data.data.token, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              path: "/",
            });
            dispatch({
              type: "AUTHENTICATION",
              payload: {
                isLogin: true,
                token: loginData.data.data.token,
                changePassword: loginData.data.data.newPassword,
              },
            });
            
            // setTimeout(() => {
            //   window.location.reload();
            // }, 500);
            navigate("/")
            setOpenModal(false);
            if (loginData.data.data.newPassword === true) {
              navigate("change/password");
            }
          }
        })
        .catch((err) => {
          // notifyError(err.response.data.message);
          setIsError({
            flag: true,
            msg: err.response.data.message,
          });
          setIsValidationWrong(false);
          setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
          setFormData({ ...formData, validationCode: "" });
        });
    } else {
      Validator.current.showMessages();
      if (confirmationCode !== parseInt(formData.validationCode)) {
        setIsValidationWrong(true);
      }
      forceUpdate();
      setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
    }
  };
  const Validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );

  const [showPass, setShowPass] = useState(false);

  return (
    <>
      <div className="inner-container">
        <div
          className={` login_main mobile_screen`}
          style={styleObjectGetBGIMageLoginImage(DD?.mobileLoginImage) || {}}
        >
          <a id="close" className="close" href="/">
            <svg
              stroke="currentColor"
              fill="#000"
              strokeWidth="0"
              viewBox="0 0 512 512"
              className="fs-1 p-1 cursor-pointer"
              height="30"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </a>
          <div className={`login-screen login_main_mobile`}>
            <div className="login-logo">
              <img src={getImageUrl(DD?.logo)} alt="" />
            </div>
            <div
              className="login_popup_image"
              style={styleObjectGetBGIMageLoginImage(DD?.loginImage) || {}}
            ></div>
            <div className="login_form_main login-panel">
              <h3
                style={styleObjectGetColor(DD?.colorSchema, false, true)}
                className="form_title"
              >
                {" "}
                LOGIN{" "}
              </h3>

              <form
                className={`bg-white ${DD?.colorSchema}`}
                onSubmit={(e) => handelSubmit(e)}
              >
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      autoComplete="off"
                      name="username"
                      placeholder="Username"
                      type="text"
                      id="floatingInput"
                      className="form-control"
                      value={formData?.username}
                      onChange={handelInputChange}
                      // style={styleObjectGetBorderColor(DD?.colorSchema)}
                      style={isError.flag ? { borderColor:"red" } : {}}
                    />
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      className="position-absolute translate-middle-y top-50"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"></path>
                    </svg>
                    <label htmlFor="floatingInput">Username</label>
                  </div>
                  {Validator.current.message(
                    "userName",
                    formData.username,
                    "required"
                  ) ? (
                    <span className="error" style={{ color: "red" }}>
                      {Validator.current.message(
                        "userName",
                        formData.username,
                        "required"
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4">
                  <div className="form-floating">
                    <input
                      autoComplete="off"
                      placeholder="Password"
                      maxLength={15}
                      name="password"
                      type={showPass ? "text" : "password"}
                      id="floatingPassword"
                      className="form-control"
                      value={formData.password}
                      onChange={handelInputChange}
                      // style={styleObjectGetBorderColor(DD?.colorSchema)}
                      style={isError.flag ? { borderColor:"red" } : {}}
                    />
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      className="position-absolute  translate-middle-y top-50"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                    </svg>
                    <button
                      onClick={() => setShowPass(!showPass)}
                      type="button"
                      className="position-absolute eye-img translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0  btn btn-primary"
                    >
                      {!showPass ? (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 640 512"
                          className="opacity-100 fs-5"
                          height="17"
                          width="17"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path>
                        </svg>
                      ) : (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 576 512"
                          className="opacity-100 fs-5"
                          height="17"
                          width="17"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
                        </svg>
                      )}
                    </button>
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  {Validator.current.message(
                    "password",
                    formData.password,
                    "required"
                  ) ? (
                    <span className="error" style={{ color: "red" }}>
                      {Validator.current.message(
                        "password",
                        formData.password,
                        "required"
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {isCaptcha === 'true' && 
                  <div className="mb-4">
                    <div className="form-floating">
                      <input
                        autoComplete="off"
                        maxLength={4}
                        name="validationCode"
                        placeholder="Validation Code"
                        type="number"
                        id="floatingValid"
                        className="form-control"
                        value={formData.validationCode}
                        onChange={handelInputChange}
                        // style={styleObjectGetBorderColor(DD?.colorSchema)}
                      />
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        className="position-absolute translate-middle-y top-50"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z"></path>
                      </svg>
                      <div
                        style={styleObjectGetColor(DD?.colorSchema, false, true)}
                        className="validation_code"
                      >
                        <h3>{confirmationCode}</h3>
                      </div>
                      <label htmlFor="floatingValid">ValidationCode</label>
                    </div>
                    {Validator.current.message(
                      "validationCode",
                      formData.validationCode,
                      "required"
                    ) ? (
                      <span className="error" style={{ color: "red" }}>
                        {Validator.current.message(
                          "validationCode",
                          formData.validationCode,
                          "required"
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                }
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="custom-switch"
                    className="form-check-input"
                  />
                  <label
                    title=""
                    htmlFor="custom-switch"
                    className="form-check-label"
                  >
                    Remember me
                  </label>
                </div>
                {isError.flag && (
                  <div
                    style={{
                      paddingTop: "10px",
                      color: "red",
                      fontSize: "16px",
                    }}
                  >
                    <span>{isError.msg}</span>
                  </div>
                )}
                <div className="text-center mt-1">
                  {/* <button type="submit" className="theme-btn ms-auto px-5 btn btn-primary">Login</button> */}
                  <button
                    type="submit"
                    className="login_btn"
                    style={styleObjectGetBGasColor(DD?.colorSchema)}
                    onSubmit={(e) => handelSubmit(e)}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
