import React, { useEffect, useState } from 'react'
import BottomFooter from '../BottomFooter'
import Loader from '../Loader'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { ADMIN_API } from '../../common/common';
import { postApiAdmin } from '../../service';
import { Logout } from '../../common/Funcation';
import moment from 'moment';

const whoitsforsettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // variableWidth: true,
  arrows: false,
};

let dropdown = [
  { name: 'All', value: 'all' },
  { name: 'Date', value: 'date' },
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' }
]



function CurrentBet(_props: any) {
  const [isLoadingMain, setIsLoadingMain] = useState(false)
  const [activeCategory, setActiveCategory] = useState('exchange')
  const [activeDD, setActiveDD] = useState(false)
  const [during, setDuring] = useState(false)
  const navigate = useNavigate();
  const HeaderData = useSelector((e: any) => e.Header);
  const userData = useSelector((e: any) => e.userData);
  const DD = useSelector((e: any) => e.domainDetails);
  const userId = HeaderData?._id
  const [pageData, setPageData] = useState<any>({})
  const [selectedDropdown, setSelectedDropdown] = useState('all')
  const [openBet, setOpenBet] = useState('')


  // useEffect(() => {
  //   getPageData()
  //   return () => {
  //   }
  // }, [])

  useEffect(() => {
    getPageData()
    return () => {
    }
  }, [activeCategory, selectedDropdown])


  const getPageData = async () => {
    setIsLoadingMain(true)
    let data: any = {
      api: ADMIN_API.PLAYER_BET_HISTORY,
      value: {
        id: userId ? userId : userData?._id,
        bet: 'active',
        betStatus: 'all',
        betType: activeCategory,
        filter: selectedDropdown
      },
    }


    await postApiAdmin(data).then(function (response) {
      console.log(response);
      setPageData(response.data.data)
      setIsLoadingMain(false)
    }).catch(err => {
      if (err.response.data.statusCode === 401) {
        setIsLoadingMain(false)
        Logout()
        navigate('/login')
      }
    })
  }



  const handleDDselect = (value: any) => {
    setSelectedDropdown(value)
    setActiveDD(false)
  }

  const handleOpenBetAccordian = (value: any) => {
    if (value === openBet) {
      setOpenBet('')
    }
    else {
      setOpenBet(value)
    }
  }


  return (
    <>
      <div className='full-wrap bottomfooter leagues'>
        {isLoadingMain &&
          <div className='loader_top'>
            <Loader />
          </div>
        }

        <div className="balance-label position-relative">
          <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => navigate('/')}>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
            </svg>
          </button>
          Current Bets
        </div>

        <div className="pt-0 inner-sidebar-content">
          <div className='sports-tab-panel py-2 px-0'>
            <Slider {...whoitsforsettings}>
              <div className={` sports-tab-panel-item ${activeCategory === 'exchange' ? "active" : ""} `} onClick={() => setActiveCategory('exchange')}>
                <div>
                  <h3>Exchange</h3>
                </div>
              </div>
              <div className={` sports-tab-panel-item ${activeCategory === 'bookMark' ? "active" : ""} `} onClick={() => setActiveCategory('bookMark')}>
                <div>
                  <h3>BookMark</h3>
                </div>
              </div>
              {/* <div className={` sports-tab-panel-item ${activeCategory === 'fancybet' ? "active" : ""} `} onClick={() => setActiveCategory('fancybet')}>
                    <div>
                      <h3>Fancybet</h3>
                    </div>
                  </div> */}
              <div className={` sports-tab-panel-item ${activeCategory === 'sportsBook' ? "active" : ""} `} onClick={() => setActiveCategory('sportsBook')}>
                <div>
                  <h3>Sportsbook</h3>
                </div>
              </div>
              <div className={` sports-tab-panel-item ${activeCategory === 'binary' ? "active" : ""} `} onClick={() => setActiveCategory('binary')}>
                <div>
                  <h3>Binary</h3>
                </div>
              </div>
              {/* <div className={` sports-tab-panel-item ${activeCategory === 'tie' ? "active" : ""} `} onClick={() => setActiveCategory('tie')}>
                    <div>
                      <h3>Tie</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'toss' ? "active" : ""} `} onClick={() => setActiveCategory('toss')}>
                    <div>
                      <h3>Toss</h3>
                    </div>
                  </div> */}
              {/* <div className={` sports-tab-panel-item ${activeCategory === 'casino' ? "active" : ""} `} onClick={() => setActiveCategory('casino')}>
                    <div>
                      <h3>Casino</h3>
                    </div>
                  </div>
                  <div className={` sports-tab-panel-item ${activeCategory === 'parlay' ? "active" : ""} `} onClick={() => setActiveCategory('parlay')}>
                    <div>
                      <h3>Parlay</h3>
                    </div>
                  </div> */}
            </Slider>
          </div>

          <div className="pl-filter">
            <div className="select-container bet-history-dropdown">
              <div className="dropdown">
                <button type="button" onClick={() => setActiveDD(!activeDD)} id="dropdown-basic-button" aria-expanded="false"
                  className={`${activeDD ? 'show' : ''} dropdown-toggle btn btn-primary`}>
                  Bet Status {selectedDropdown}
                </button>

                <div x-placement="bottom-start" aria-labelledby="dropdown-basic-button" className={`${activeDD ? 'show' : ''} dropdown-menu`} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-start"
                >

                  {dropdown.filter((_) => _.value !== selectedDropdown).map((item, i) => {
                    return (
                      <>
                        <a className="dropdown-item" onClick={() => handleDDselect(item.value)}> {item.name}</a> </>
                    )
                  })}
                </div>
              </div>
              <div>
                <form>
                  <div className="d-flex align-items-center justify-content-end order-checkbox">
                    <label className="text-yellow pe-2 pb-0 mb-0 form-label">Order By</label>
                    <div className="text-white d-flex form-check">
                      <input type="checkbox" id="default-checkbox" className="form-check-input" />
                      <label title="" htmlFor="default-checkbox" className="form-check-label">Bet Placed</label>
                    </div>
                    <div className="text-white d-flex form-check">
                      <input type="checkbox" id="disabled-default-checkbox" className="form-check-input" />
                      <label title="" htmlFor="disabled-default-checkbox" className="form-check-label">Market</label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="p-3">
            {pageData && pageData?.length > 0 ? pageData.map((item: any, i: any) => {
              return (
                <div className="all-bets position-relative">
                  <div className={`current-bets-table mt-3  ${openBet === i ? 'show' : ''}`}>
                    <table className="overflow-hidden bg-white mb-0 odds-table table table-bordered">
                      <thead>
                        <tr>
                          <th colSpan={4}>
                            {item.type}
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="arrow-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"></path>
                            </svg>
                            <span className="master-pass">{item.name}</span>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="arrow-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"></path>
                            </svg>
                            <span className="master-pass">{item.betType}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="lay-back">
                          <td colSpan={3}> <span className={`bet-name ${item.betSide === 'back' ? 'back' : 'lay'} `}>{item.betSide} </span> {item.selection}</td>
                        </tr>
                        <tr>
                          <td>Odds req. <strong className="d-block">{item.oddsUp}</strong></td>
                          <td>Avg. Odds <strong className="d-block">{item.oddsUp}</strong></td>
                          <td>Matched (PBU)<strong className="d-block">{item.stake}</strong></td>
                        </tr>
                        <tr>
                          <td>Bet ID</td>
                          <td colSpan={2}>{item._id}</td>
                        </tr>
                        <tr>
                          <td>Bet Placed</td>
                          <td colSpan={2}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                        </tr>
                      </tbody>
                    </table>

                    {openBet === i &&
                      <table className="bets-table mb-0 table">
                        <thead>
                          <th className="pe-0">Bet Taken</th>
                          <th colSpan={2}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Odds req. <strong className="d-block">{item.oddsUp}</strong></td>
                            <td>Avg. Odds <strong className="d-block">{item.oddsUp}</strong></td>
                            <td>Matched (PBU)<strong className="d-block">{item.stake}</strong></td>
                          </tr>
                          <tr>
                            <td>Profit (PBU)</td>
                            <td colSpan={2} className="text-success">{item.profit}</td>
                          </tr>
                        </tbody>
                      </table>
                    }

                  </div>
                  <button className={`show-hide-bet  ${openBet === i ? 'arrow-up' : ''}`} onClick={() => handleOpenBetAccordian(i)}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                    </svg>
                  </button>
                </div>

              )
            }) :

              <div className="no-event-outer">
                <div className="no-event">
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" color="lightgrey" className="mb-2" height="55" width="55" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"></path>
                  </svg>
                  No Event
                </div>
              </div>

            }


          </div>

        </div>






      </div>
      <BottomFooter />



      {during && <div className="market-depth-modal">
        <div className="market-title">
          <h4>During</h4>
          <button type="button" onClick={() => setDuring(false)} className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary">
            <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
            </svg>
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="p-2 calendar-button"><button type="button" className="btn btn-primary">Today</button><button type="button" className="btn btn-primary">From Yesterday</button><button type="button" className="btn btn-primary">Last 7 Days</button></div>
        </div>
      </div>
      }
    </>



  )
}

export default CurrentBet