/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { USER_API } from "../../common/common";
import { getComparator, Logout, stableSort } from "../../common/Funcation";
import Loader from "../../components/Loader";
// import { bookExposerGetPopup } from '../../redux/reducer';
import {
  notifyError,
  notifyMessage,
  postApi,
  postApiLink,
  sendEvent,
} from "../../service";
import Login from "../Login";
import BetBox from "./BetBox";
import {
  DetailsTableInterface,
  FancyInterface,
  PinInterface,
  PreMainInterface,
  PreSubInterface,
} from "./interface";
import FancyInfoModal from "./FancyInfoModal";
import PremiumInfoModal from "./PremiumInfoModal";
import Slider from "react-slick";
import MarketDepthPopup from "./MarketDepthPopup";
import ReactPlayer from "react-player";
import OddsDammyData from "../../components/multiMarket/OddsDammyData";

const fancySetting = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  // variableWidth: true,
  arrows: false,
};

const premiumSetting = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // variableWidth: true,
  arrows: false,
};

const sportsWidgetSetting = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  // variableWidth: true,
  arrows: false,
};

const cookies = new Cookies();
const MultiMarket = () => {
  let interval: string | number | NodeJS.Timeout | undefined;
  const HeaderData = useSelector((e: any) => e.Header);
  const isMountedRef = useRef(true);
  const [isLoadingMain, setIsLoadingMain] = useState(false);
  const isAuthenticated = useSelector((e: any) => e.isAuthenticated);
  const SPORT_DETAILS = useSelector((e: any) => e.sportDetails);
  const BET_HISTORY = useSelector((e: any) => e.betHistory);
  const SCORE_CARD = useSelector((e: any) => e.scoreCard);
  const [placeBetLoader, setPlaceBetLoader] = useState(false);
  // const [mainLoader, setMainLoader] = useState(true);
  const [tab, setTab] = useState("cricket");
  const [fancyPremium, setFancyPremium] = useState(true);
  const dispatch = useDispatch();
  const [openLive, setopenLive] = useState<any>("live");
  const [detailsPage, setDetailsPage] = useState<any>({
    eventId: "",
    marketId: "",
  });
  const [detailSport, setDetailSport] = useState(SPORT_DETAILS);
  const [clickedBet, setClickedBet] = useState<DetailsTableInterface | any>();
  const [clickedTableData, setClickedTableData] = useState<string>("");
  let { eventId, marketId } = useParams<any>();
  const [openedPre, setOpenedPre] = useState<any>([]);
  const [clickedTable, setClickedTable] = useState<string>("");
  const [videoDetails, setVideoDetails] = useState<any>({});
  const [loginPopup, setLoginPopup] = useState(false);
  const [positonModal, setPositonModal] = useState<boolean>(false);
  const [HistorySuccess, setHistorySuccess] = useState(false);
  const [positonDetails, setPositonDetails] = useState<any>({
    data: [],
    type: "",
  });
  const [isSportLoading, setIsSportLoading] = useState<boolean>(true);
  const [blockStatus, setBlockStatus] = useState<any>({});
  const [t4Main, setT4Main] = useState<any>({});
  const [sportsWidget, setSportsWidget] = useState<any>([]);
  const [chennalID, setChennalID] = useState<any>(0);

  if (
    window.performance &&
    detailsPage.eventId &&
    detailsPage.marketId &&
    window.innerWidth < 993
  ) {
    if (performance.navigation.type == 1) {
      // navigate('/')
    } else {
    }
  }

  const getBlockStatus = async () => {
    let data = {
      api: USER_API.GET_BLOCK,
      value: {
        matchId: "63f3992e1ac591eee195eb31",
      },
    };

    await postApi(data)
      .then(function (response) {
        if (JSON.stringify(response.data.data.blockMatchDetail) === "{}") {
          setBlockStatus({
            blockAll: false,
            blockOdds: false,
            blockBookMaker: false,
            blockFancy: false,
            blockPremium: false,
            //   matchId: id
          });
        } else {
          setBlockStatus(response.data.data.blockMatchDetail);
        }
      })
      .catch((err) => {
        debugger;
        if (err.response.data.statusCode === 401) {
          Logout();
          // navigate('/login')
        }
      });
  };

  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    debugger;
    if (isAuthenticated && cookies.get("skyTokenFront")) {
      getPageData("cricket");
    }
    getSportWidget(eventId);
    return () => {};
  }, []);

  useEffect(() => {
    setIsLoadingMain(true);
    setDetailSport(SPORT_DETAILS);
    if (JSON.stringify(SPORT_DETAILS) !== "{}") {
      setIsLoadingMain(false);
    }
    return () => {};
  }, [SPORT_DETAILS]);

  const getPageData = async (TAB: string) => {
    setIsLoadingMain(true)
    let data = {
      api: USER_API.MULTI_LIST,
      value: {
        type: TAB,
      },
    };

    await postApi(data)
      .then(function (response) {
        console.log("::::::::::>>>>", response);
        setPageData(response.data.data);
        setIsLoadingMain(false)
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          setIsLoadingMain(false)
          Logout();
          // navigate('/login')
        }
      });
  };
  const getSportWidget = async (id: any) => {
    let data = {
      api: USER_API.GET_SPORTS_WIDGET,
      value: {
        gameId: id,
      },
    };

    await postApi(data)
      .then(function (response) {
        console.log("::::::::::>>>>", response);
        setSportsWidget(response.data.data.sportDetail);
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          // Logout()
          // navigate('/login')
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      sendEvent("GET_SPORTS", { type: "cricket" });
    }, 3000);
    window.onbeforeunload = () => {
      dispatch({ type: "GET_SCORE_ID", payload: [] });
    };
    getVideoUrl();
    getchennalIDForVideoUrl();

    return () => {
      dispatch({ type: "GET_SPORTS_DETAILS", payload: {} });
      setDetailSport({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMatchData = () => {
    setIsLoadingMain(true)
    let data: any = {};
    if (eventId && marketId) {
      setDetailsPage({ eventId: parseInt(eventId), marketId: marketId });

      data.eventId = parseInt(eventId);
      data.marketId = marketId;
      data.domain = window.location.hostname;
      let num = 1;
      // clearInterval(interval)
      interval = setInterval(() => {
        if ((HistorySuccess || BET_HISTORY) && isMountedRef.current) {
          if(num === 1 || (isAuthenticated && cookies.get("skyTokenFront"))){
            sendEvent("GET_SPORTS_DETAILS", data);
            num += 1;
          }
          if (isAuthenticated && cookies.get("skyTokenFront")) {
            getBlockStatus();
          }
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (cookies.get("skyTokenFront")) {
      let data: any = {};
      if (eventId && marketId) {
        setDetailsPage({ eventId: parseInt(eventId), marketId: marketId });

        data.eventId = parseInt(eventId);
        data.marketId = marketId;
        data.domain = window.location.hostname;
        if (HistorySuccess || BET_HISTORY) {
          setTimeout(() => {
            sendEvent("GET_SPORTS_DETAILS", data);
          }, 500);
        }
      }
    }
    return () => {
      dispatch({ type: "GET_SPORTS_DETAILS", payload: {} });
      setDetailSport({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HistorySuccess]);

  useEffect(() => {
    setIsLoadingMain(true);
    getMatchData();
    return () => {
      isMountedRef.current = false;
      clearInterval(interval);
      dispatch({ type: "GET_SPORTS_DETAILS", payload: {} });
      setDetailSport({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const pinClick = (e: any, SUBITEM: PinInterface) => {
  //     console.log(SUBITEM);
  //     setDetailsPage({ eventId: SUBITEM.gameId, marketId: SUBITEM.marketId })
  //     navigate(SUBITEM.gameId + '/' + SUBITEM.marketId)
  //     sendEvent('GET_SPORTS_DETAILS', { eventId: SUBITEM.gameId, marketId: SUBITEM.marketId })
  // }

  const BetClick = (
    ITEM:
      | DetailsTableInterface
      | PreMainInterface
      | FancyInterface
      | PreSubInterface,
    TD: string,
    TABLE: string,
    _T4MAIN: any = undefined
  ) => {
    resetBet();
    dispatch({
      type: "GET_SPORTS_LIVE_CALC",
      payload: { calcOntime: false, calcData: {} },
    });
    if (cookies.get("skyTokenFront")) {
      console.log("clicked Table", TD, ITEM);
      setClickedBet(ITEM);
      setClickedTableData(TD);
      setClickedTable(TABLE);
      if (TABLE === "t4" && _T4MAIN) {
        setT4Main(_T4MAIN);
      } else setT4Main({});
    } else {
      if (window.innerWidth > 993) {
        setLoginPopup(true);
      } else {
        window.location.pathname = "/login";
      }
    }
  };

  const resetBet = () => {
    setClickedBet({});
    setClickedTableData("");
    setClickedTable("");
    dispatch({
      type: "GET_SPORTS_LIVE_CALC",
      payload: { calcOntime: false, calcData: {} },
    });
  };
  const OpenCollopsPre = (id: number) => {
    if (openedPre.includes(id)) {
      let openedPreCopy = openedPre;
      openedPreCopy = openedPre.filter((_: number) => _ !== id);
      setOpenedPre(openedPreCopy);
    } else {
      setOpenedPre([...openedPre, id]);
    }
  };
  const getBetTYPE = (TABLE: string) => {
    switch (TABLE) {
      case "t1":
        return "odds";
      case "t2":
        return "bookMark";
      case "t3":
        return "session";
      case "t4":
        return "premium";
      default:
        break;
    }
  };
  const getSecondValue = (FIRST: string) => {
    switch (FIRST) {
      case "b1":
        return "bs1";
      case "b2":
        return "bs2";
      case "b3":
        return "bs3";
      case "l1":
        return "ls1";
      case "l2":
        return "ls2";
      case "l3":
        return "ls3";
      default:
        return "bs1";
    }
  };
  const getBetSide = (TABLE: string, TYPE: string) => {
    let B = ["b1", "b2", "b3"];
    let L = ["l1", "l2", "l3"];
    if (TABLE === "t4") {
      return "back";
    }
    if (TABLE === "t1" || TABLE === "t2") {
      if (B.includes(TYPE)) {
        return "back";
      }
      if (L.includes(TYPE)) {
        return "lay";
      }
    }
    if (TABLE === "t3") {
      return TYPE === "b1" ? "yes" : "no";
    }
  };

  const PlaceBat = async (
    ITEM: any,
    TotalAmount: any,
    SelectedAmount: number,
    TABLE: string,
    TABLE_DATA: string,
    retundData: boolean = false
  ) => {
    console.log("::::::-----I", ITEM);
    console.log("::::::-----P", detailSport);
    let B = ["b1", "b2", "b3"];
    let L = ["l1", "l2", "l3"];
    let WS: any = [];
    if (retundData === false) {
      setPlaceBetLoader(true);
    }

    let filterDataTable =
      TABLE === "t1"
        ? detailSport?.page?.data?.t1
        : TABLE === "t2"
        ? detailSport?.page?.data?.t2
        : TABLE === "t2"
        ? detailSport?.page?.data?.t3
        : detailSport?.pre?.data?.t4;
    if (TABLE === "t1" || TABLE === "t2") {
      filterDataTable.forEach((element: DetailsTableInterface) => {
        WS.push(element.nat);
      });
    }
    if (TABLE === "t3") {
      WS = [ITEM.nat];
    }
    if (TABLE === "t4") {
      t4Main.sub_sb.forEach((element: DetailsTableInterface) => {
        WS.push(element.nat);
      });
    }
    console.log("::::::-----W", WS);
    console.log("::::::-----T", TABLE);
    console.log("::::::-----D", TABLE_DATA, t4Main);
    console.log("::::::-----S", getSecondValue(TABLE_DATA));
    let second = getSecondValue(TABLE_DATA);
    // "odds", "bookMark", "session", "premium"
    let data: any = {
      api: USER_API.PLACE_BET,
      value: {
        type: detailSport?.matchInfo?.type, // from match info
        matchId: detailSport?.matchInfo?._id,
        betType: getBetTYPE(TABLE), //t1 ods //t2 bookmaker //t3 session //t4 premium
        betSide: getBetSide(TABLE, TABLE_DATA), // back lay // fancy- yes no // Pre - back
        selection: TABLE === "t4" ? t4Main.marketName : ITEM.nat,
        betPlaced: SelectedAmount,
        stake: SelectedAmount,
        oddsUp: ITEM[TABLE_DATA], // b1
        oddsDown: TABLE === "t4" ? 0 : ITEM[second], //bs1
        profit: parseFloat(TotalAmount), // if l1 ? selecetdAmount  // check
        exposure: L.includes(TABLE_DATA)
          ? parseFloat(TotalAmount)
          : SelectedAmount, // if b1 TotalAmount : selecetdAmount // check
        winnerSelection: WS, //  array or name string
        // subSelection:'' // for t4  only
        sId: TABLE === "t4" ? t4Main?.id : ITEM.sId ? ITEM.sId : "",
        pId: TABLE === "t4" ? ITEM.sId : "",
        // clear amount if match change
        position: TABLE === "t4" ? "" : TABLE_DATA,
        domain: window.location.hostname,

        // fancyYes: joi.number().optional(), //
        // fancyNo: joi.number().optional(), //
        // subSelection: joi.string().allow("").optional(), // use only for premium
      },
    };
    if (TABLE === "t3") {
      data.value.fancyYes = ITEM.b1;
      data.value.fancyNo = ITEM.l1;
    }
    if (TABLE === "t4") {
      data.value.subSelection = ITEM.nat;
    }

    if (retundData) {
      let data1: any = {};
      data1.eventId = eventId ? parseInt(eventId) : eventId;
      data1.marketId = marketId;
      data1.domain = window.location.hostname;
      sendEvent("GET_SPORTS_DETAILS", data1);
      return data.value;
    }

    console.log("placeBet data ::::::-----", data);

    await postApi(data)
      .then(function (response) {
        console.log("::::::::::>>>>", response);
        setPageData(response.data.data);
        notifyMessage("Bet Placed");
        setPlaceBetLoader(false);
        bethistory();
        setTimeout(() => {
          sendEvent("UPDATE_USER_BALANCE", { userId: HeaderData?._id });
        }, 500);
        setTimeout(() => {
          sendEvent("UPDATE_USER_BALANCE", { userId: HeaderData?._id });
        }, 1500);

        resetBet();
      })
      .catch((err) => {
        debugger;
        notifyError(err.response.data.message);
        setPlaceBetLoader(false);
        if (err.response.data.statusCode === 401) {
          Logout();
          // navigate('/login')
        }
      });
    // resetBet()
  };

  // const pinMetch = async (e: any, item: PinInterface, type: string) => {
  //     if (isAuthenticated && cookies.get('skyTokenFront')) {

  //         let data = {
  //             api: USER_API.PIN,
  //             value: {
  //                 sportId: item._id,
  //                 type: type,
  //                 flag: false
  //             }
  //         }

  //         await postApi(data).then(function (response) {
  //             getPageData(tab)
  //             notifyMessage('Pin Success')
  //         }).catch(err => {
  //             console.log(err);
  //             if (err.response.data.statusCode === 401) {
  //                 Logout()
  //                 notifyError('Pin unsuccess')
  //                 // navigate('/login')
  //             }
  //         })
  //     } else {
  //         notifyError('please login first')
  //     }
  // }

  const getVideoUrl = async () => {
    setTimeout(() => {
      sendEvent("GET_SCORE_ID", { gameId: eventId });
    }, 1200);

    // https://multiexch.com/VRN/v1/api/scoreid/get?eventid=(bfeventid)
    // https://multiexch.com/VRN/v1/api/scoreid/get?eventid=32353856
    let data = {
      // api: `https://new.livingstreamvdo.com/?eventId=${eventId}`,
      api: `https://liveapi247.com/api1/livestreaming`,
      value: {
        eventid: eventId,
        // eventid: 31846646
      },
    };

    await postApiLink(data)
      .then(function (response) {
        console.log("::::::::::>>>>api123", response);
        setVideoDetails(response.data[0]);
      })
      .catch((err) => {
        console.log("::::::::::>>>>", err);
        if (err.response.data.statusCode === 401) {
          Logout();
          // navigate('/login')
        }
      });
  };
  const getchennalIDForVideoUrl = async () => {
    // https://multiexch.com/VRN/v1/api/scoreid/get?eventid=(bfeventid)
    // https://multiexch.com/VRN/v1/api/scoreid/get?eventid=32353856
    let data = {
      // api: `https://new.livingstreamvdo.com/?eventId=${eventId}`,
      api: USER_API.GET_CHANNEL_ID,
      value: {
        gameId: eventId,
        // eventid: 31846646
      },
    };

    await postApi(data)
      .then(function (response) {
        console.log("::::::::::>>>>streaminfo", response);
        // if(response?.data?.data?.getMatches){
        //    const channelData = response?.data?.data?.getMatches?.find((value:any)=> value.MatchID === marketId);
        //    console.log('::::::::::>>>>streaminfo :: channelData', channelData);
        //    if(channelData){
        setChennalID(response?.data?.data?.ChannelId);
        //    }
        // }
        // setVideoDetails(response.data[0])
      })
      .catch((err) => {
        console.log("::::::::::>>>>", err);
        // if (err.response.data.statusCode === 401) {
        // Logout()
        // navigate('/login')
        // }
      });
  };
  const closePositonPopup = () => {
    setPositonModal(false);
  };
  useEffect(() => {
    if (cookies.get("skyTokenFront")) {
      bethistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bethistory = async () => {
    let data = {
      api: USER_API.BET_LIST,
      value: {},
    };

    await postApi(data)
      .then(function (response) {
        // setPageData(response.data.data)
        dispatch({ type: "USER_BET_HISTORY", payload: response.data.data });
        setHistorySuccess(true);
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          Logout();
          // navigate('/login')
        }
      });
  };

  // const onBookClick = (ITEM: any, HISTORY: any) => {

  //     let filterHistory = BET_HISTORY.filter((_: any) => _.selection === ITEM.nat)
  //     let popupdata = bookExposerGetPopup(filterHistory)
  //     let data = [{ main: HISTORY.oddsUp - 1, second: -10 }, { main: HISTORY.oddsUp, second: 10 }, { main: HISTORY.oddsUp + 1, second: 10 }]
  //     setPositonDetails(popupdata)
  //     setPositonModal(true)
  // }

  const [fancyInfoModal, setFancyInfoModal] = useState(false);
  const [premiumInfoModal, setPremiumInfoModal] = useState(false);
  const [showBookmaker, setShowBookmaker] = useState(true);
  const [sportWidgetFlage, setSportWidgetFlage] = useState(true);

  const [mareketDept, setMareketDept] = useState(false);

  const handleNavigation = (item: any) => {
    window.location.pathname = `/multimarket/${item.gameId}/${item.marketId}`;
  };

  const handleMarketDepth = () => {
    setMareketDept(true);
  };

  
const [sparkOddsBackDetail, setSparkOddsBackDetail] = useState(0);
const [sparkOddsLayDetail, setSparkOddsLayDetail] = useState(1);
const [sparkBookBackDetail, setSparkBookBackDetail] = useState(1);
const [sparkBookLayDetail, setSparkBookLayDetail] = useState(0);
const randomeNumber = () => Math.floor((Math.random()*2));
useEffect(()=>{
  setInterval(()=>{

    console.log("sparkDetail :: ", sparkOddsBackDetail);
    
    setSparkOddsBackDetail(randomeNumber())
    setSparkOddsLayDetail(randomeNumber())
    setSparkBookBackDetail(randomeNumber())
    setSparkBookLayDetail(randomeNumber())
    setTimeout(()=>{
      setSparkOddsBackDetail(-1)
      setSparkOddsLayDetail(-1)
      setSparkBookBackDetail(-1)
      setSparkBookLayDetail(-1)
    },1000)
  }, 5000)
},[])

  console.log("detailSport", detailSport);

  return (
    <>
      {isLoadingMain && <Loader />}
      {placeBetLoader && (
        <div className="loader_top">
          {" "}
          <Loader />{" "}
        </div>
      )}

      <div className="fullmarket">
        <div
          className={` ${cookies.get("skyTokenFront") ? "live_match" : ""} `}
        >
          {cookies.get("skyTokenFront") && (
            <div className="topnav">
              <p
                className={`${openLive === "live" ? "active" : ""}`}
                onClick={() => setopenLive("live")}
              >
                Live
              </p>
              <p
                className={`${openLive === "scoreboard" ? "active" : ""}`}
                onClick={() => setopenLive("scoreboard")}
              >
                ScoreBoard
              </p>
            </div>
          )}
          <div>
            <div className="live-header">
              <span>{detailSport?.matchInfo?.name?.split("v")[0]}</span> -{" "}
              <span>{detailSport?.matchInfo?.name?.split("v")[1]}</span>
            </div>

            {cookies.get("skyTokenFront") ? (
              openLive === "scoreboard" ? (
                SCORE_CARD.length > 0 &&
                cookies.get("skyTokenFront") && (
                  <iframe
                    src={`https://www.satsports.net/score_widget/index.html?id=${SCORE_CARD?.[0]?.score_id}&amp;aC=bGFzZXJib29rMjQ3`}
                    width="100%"
                  ></iframe>
                )
              ) : // <iframe className='responsive-iframe w-100' id='Iframe' src={`https://startv247.live/play/${eventId}`} width="100%"></iframe>

              chennalID ? (
                // <ReactPlayer
                //     height="100%"
                //     width="100%"
                //     url={`https://mediasrv789-ss247-23-prod-sa-ulivestreaming.com/${Number(chennalID)}/index.m3u8`}
                //     // url={`https://new.livingstreamvdo.com/?eventId=${eventId}`}
                //     // url='https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
                //     controls
                //     playing
                // />
                <div className="cst_live_tv_section live_tv">
                  <iframe
                    style={{
                      minHeight: "206px",
                      height: "100%",
                      overflow: "hidden",
                    }}
                    className="cst_live_tv responsive-iframe w-100"
                    id="Iframe"
                    // src={`https://ss247.life/api/13eb1ef122caaff1a8398292ef0a4f67f52eb748/Nstreamapi.php?chid=${chennalID}`}
                    src={`https://e765432.xyz/static/69fb31e65e4ed5d6eaebf3b8b0e0e6a715c77cc6/getdata.php?chid=${chennalID}`} 
                    width="100%"
                  ></iframe>
                </div>
              ) : (
                <div className="cst_live_tv_section live_tv">
                  <img src="/images/tv_img.png" alt="tv_image" />
                </div>
              )
            ) : (
              SCORE_CARD.length > 0 && (
                <div className="cst_live_tv_section score_card">
                  {" "}
                  <iframe
                    src={`https://www.satsports.net/score_widget/index.html?id=${SCORE_CARD?.[0]?.score_id}&amp;aC=bGFzZXJib29rMjQ3`}
                    width="100%"
                  ></iframe>{" "}
                </div>
              )
            )}
          </div>
        </div>

        {!blockStatus?.blockAll &&
        !blockStatus?.blockOdds &&
        detailSport?.page?.data?.t1?.length > 0 ? (
          <>
            <div className="balance-label-detail position-relative text-start d-flex justify-content-between exchange-label">
              <span>Exchange</span>
              <div className="text-yellow balance-label-inner">
                <span>Matched</span>
                <strong className="px-2">
                  PBU {detailSport?.page?.data?.t1[0]?.totalmatch}
                </strong>
              </div>
            </div>
            <div className="match-odds-sec">
              <div className="bg-yellow d-flex match-odd-header-outer ">
                <div className=" d-flex align-items-center match-odd-header-text match-odd-header">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path>
                  </svg>
                  Match Odds
                </div>
              </div>
              <div className="match-odd-table bg-white p-3 px-2 py-1">
                <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                  <span className="text-center w-100">Back</span>
                  <span className="text-center w-100">Lay</span>
                </div>
                <ul className="mb-2 rounded position-relative p-0">
                  {detailSport &&
                    stableSort(
                      detailSport?.page?.data?.t1,
                      getComparator("asc", "sortPriority")
                    ).map((item: DetailsTableInterface, i) => {
                      return (
                        <>
                          <li key={i}>
                            <h2>
                              {item.nat}
                              {item?.betProfit &&
                                cookies.get("skyTokenFront") && (
                                  <span className="d-block py-1 team-red-score">
                                    <span
                                      className={` ${
                                        item.betProfit < 0
                                          ? "text-danger"
                                          : "text-green"
                                      } team_total`}
                                    >
                                      {" "}
                                      &gt; (
                                      {parseFloat(item.betProfit)?.toFixed(2)})
                                    </span>
                                  </span>
                                )}
                            </h2>
                            <div className="lay-back-table ">
                              <div
                                onClick={() => BetClick(item, "b1", "t1")}
                                id="back_odds_0"
                                className={`${
                                  clickedBet?.sId === item.sId &&
                                  clickedTableData === "b1"
                                    ? "back-active"
                                    : ""
                                } back ${sparkOddsBackDetail === i ? "spark-back" : ""}`}
                              >
                                <span
                                  className={`${
                                    item.status === "SUSPEND" ||
                                    !(
                                      detailSport?.matchInfo?.oddsLimit?.min <=
                                        item.b1 &&
                                      detailSport?.matchInfo?.oddsLimit?.max >=
                                        item.b1
                                    )
                                      ? "single-disabled"
                                      : ""
                                  }`}
                                >
                                  {(item.status === "SUSPEND" ||
                                    !(
                                      detailSport?.matchInfo?.oddsLimit?.min <=
                                        item.b1 &&
                                      detailSport?.matchInfo?.oddsLimit?.max >=
                                        item.b1
                                    )) && <span className="disabled"> </span>}
                                  <strong>{item.b1}</strong>
                                  <span className="d-block">{item.bs1}</span>
                                </span>
                              </div>

                              <div
                                onClick={() => BetClick(item, "l1", "t1")}
                                id="lay_odds_0"
                                className={`${
                                  clickedBet?.sId === item.sId &&
                                  clickedTableData === "l1"
                                    ? "lay-active"
                                    : ""
                                } lay ${sparkOddsLayDetail === i ? "spark-lay" : ""}`}
                              >
                                <span
                                  className={`${
                                    item.status === "SUSPEND" ||
                                    !(
                                      detailSport?.matchInfo?.oddsLimit?.min <=
                                        item.l1 &&
                                      detailSport?.matchInfo?.oddsLimit?.max >=
                                        item.l1
                                    )
                                      ? "single-disabled"
                                      : ""
                                  }`}
                                >
                                  {(item.status === "SUSPEND" ||
                                    !(
                                      detailSport?.matchInfo?.oddsLimit?.min <=
                                        item.l1 &&
                                      detailSport?.matchInfo?.oddsLimit?.max >=
                                        item.l1
                                    )) && <span className="disabled"> </span>}
                                  <strong>{item.l1}</strong>
                                  <span className="d-block">{item.ls1}</span>
                                </span>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })}
                </ul>

                <div className="market-depth-sec d-flex justify-content-end pt-1 justify-content-between items-content-center">
                  <button
                    onClick={() => handleMarketDepth()}
                    className="market-depth-sec-btn inline-flex justify-center items-center rounded bg-secondary text-global-font-on-dark px-3px py-1.5"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.0"
                        width="164.000000pt"
                        height="170.000000pt"
                        viewBox="0 0 164.000000 170.000000"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,170.000000) scale(0.100000,-0.100000)"
                          fill="#000000"
                          stroke="none"
                        >
                          <path d="M840 1065 l0 -515 130 0 130 0 0 515 0 515 -130 0 -130 0 0 -515z" />
                          <path d="M160 975 l0 -425 125 0 125 0 0 425 0 425 -125 0 -125 0 0 -425z" />
                          <path d="M1180 890 l0 -340 130 0 130 0 0 340 0 340 -130 0 -130 0 0 -340z" />
                          <path d="M500 805 l0 -255 130 0 130 0 0 255 0 255 -130 0 -130 0 0 -255z" />
                          <path d="M160 250 l0 -130 685 0 685 0 0 130 0 130 -685 0 -685 0 0 -130z" />
                        </g>
                      </svg>
                    </div>
                    <span className="px-3px text-11"> Market Depth </span>
                  </button>
                  <div className="d-flex align-items-center min-max">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 256 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M214.059 377.941H168V134.059h46.059c21.382 0 32.09-25.851 16.971-40.971L144.971 7.029c-9.373-9.373-24.568-9.373-33.941 0L24.971 93.088c-15.119 15.119-4.411 40.971 16.971 40.971H88v243.882H41.941c-21.382 0-32.09 25.851-16.971 40.971l86.059 86.059c9.373 9.373 24.568 9.373 33.941 0l86.059-86.059c15.12-15.119 4.412-40.971-16.97-40.971z"></path>
                    </svg>
                    <span>min/max</span>
                    {/* <strong>1/10,000</strong> */}
                    <strong>
                            {detailSport?.matchInfo?.bet_odds_limit?.min}/
                            {detailSport?.matchInfo?.bet_odds_limit?.max}
                          </strong>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
       {/* { <OddsDammyData detailSport={detailSport} />} */}

        {detailSport?.matchInfo?.activeStatus?.bookmaker &&
        !blockStatus?.blockAll &&
        !blockStatus?.blockBookMaker &&
        detailSport?.page?.data?.t2.length > 0 ? (
          <div className="bookmaker p-2">
            <div className="accordion">
              <div className="accordion-item">
                <h2
                  className={`${
                    showBookmaker ? "active" : ""
                  } accordion-header`}
                  onClick={() => setShowBookmaker(!showBookmaker)}
                >
                  <button
                    type="button"
                    aria-expanded="true"
                    className="accordion-button"
                  >
                    <div className="d-flex align-items-center">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path>
                      </svg>
                      Bookmaker
                    </div>
                  </button>
                </h2>
                <div
                  className={`${
                    showBookmaker ? "slide-down" : "slide-up"
                  } accordion-collapse `}
                >
                  <div className="p-0 accordion-body">
                    <div className="match-odd-table bg-white p-3 px-2 py-1 pe-0">
                      <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                        <span className="text-center w-100">Back</span>
                        <span className="text-center w-100">Lay</span>
                      </div>
                      <ul className="mb-2 rounded position-relative p-0">
                        {detailSport &&
                          stableSort(
                            detailSport?.page?.data?.t2,
                            getComparator("asc", "sortPriority")
                          ).map((item: DetailsTableInterface, i) => {
                            return (
                              <li key={i}>
                                <h2>{item.nat}
                                {item?.betProfit &&
                                cookies.get("skyTokenFront") && (
                                  <span className="d-block py-1 team-red-score">
                                    <span
                                      className={` ${
                                        item.betProfit < 0
                                          ? "text-danger"
                                          : "text-green"
                                      } team_total`}
                                    >
                                      {" "}
                                      &gt; (
                                      {parseFloat(item.betProfit)?.toFixed(2)})
                                    </span>
                                  </span>
                                )}
                                </h2>
                                <div className="lay-back-table bg-bookmaker-market">
                                  <div
                                    id="back_odds_0"
                                    className={`${
                                      clickedBet?.sId === item.sId &&
                                      clickedTableData === "b1"
                                        ? "back-active"
                                        : ""
                                    } back ${sparkBookBackDetail === i ? "spark-back" : ""}`}
                                  >
                                    <span
                                      className={`${
                                        item.status === "SUSPEND"
                                          ? "single-disabled"
                                          : ""
                                      }`}
                                      onClick={() => BetClick(item, "b1", "t2")}
                                    >
                                      {item.status === "SUSPEND" && (
                                        <span className="disabled"> </span>
                                      )}
                                      <strong>{item.b1}</strong>
                                      <span className="d-block">
                                        {item.bs1}
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    id="lay_odds_0"
                                    className={`${
                                      clickedBet?.sId === item.sId &&
                                      clickedTableData === "l1"
                                        ? "lay-active"
                                        : ""
                                    } lay ${sparkBookLayDetail === i ? "spark-lay": "" }`}
                                  >
                                    <span
                                      className={`${
                                        item.status === "SUSPEND"
                                          ? "single-disabled"
                                          : ""
                                      }`}
                                      onClick={() => BetClick(item, "l1", "t2")}
                                    >
                                      {item.status === "SUSPEND" && (
                                        <span className="disabled"> </span>
                                      )}
                                      <strong>{item.l1}</strong>
                                      <span className="d-block">
                                        {item.ls1}
                                      </span>
                                    </span>
                                  </div>
                                  {item.status === "SUSPEND" && (
                                    <div className="overlay-match">
                                      Suspended
                                    </div>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                      <div className="market-depth-sec d-flex justify-content-end">
                        <div className="d-flex align-items-center min-max">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 256 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M214.059 377.941H168V134.059h46.059c21.382 0 32.09-25.851 16.971-40.971L144.971 7.029c-9.373-9.373-24.568-9.373-33.941 0L24.971 93.088c-15.119 15.119-4.411 40.971 16.971 40.971H88v243.882H41.941c-21.382 0-32.09 25.851-16.971 40.971l86.059 86.059c9.373 9.373 24.568 9.373 33.941 0l86.059-86.059c15.12-15.119 4.412-40.971-16.97-40.971z"></path>
                          </svg>
                          <span>min/max</span>
                          <strong>
                            {detailSport?.matchInfo?.bet_bookmaker_limit?.min}/
                            {detailSport?.matchInfo?.bet_bookmaker_limit?.max}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="fancy-sport-tab p-2">
          {detailSport?.page?.data?.t3?.length ||
          detailSport?.pre?.data?.t4?.length ? (
            <div className="fancy-sport-header">
              <div className="d-flex align-items-center taber">
                {detailSport?.page?.data?.t3?.length ? (
                  <button
                    type="button"
                    className={` ${
                      fancyPremium ? "active-taber" : ""
                    } btn btn-primary`}
                    onClick={
                      detailSport?.page?.data?.t3?.length > 0 &&
                      detailSport?.matchInfo?.activeStatus?.fancy
                        ? () => setFancyPremium(true)
                        : () => console.log()
                    }
                  >
                    Fancybet
                  </button>
                ) : (
                  ""
                )}

                {detailSport?.pre?.data?.t4?.length ? (
                  <button
                    type="button"
                    className={` ${
                      fancyPremium ? "" : "active-taber"
                    } btn btn-primary`}
                    onClick={
                      detailSport?.pre?.data?.t4?.length > 0 &&
                      detailSport?.matchInfo?.activeStatus?.premium
                        ? () => setFancyPremium(false)
                        : () => console.log()
                    }
                  >
                    Premium Fancy
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {fancyPremium &&
          detailSport?.matchInfo?.activeStatus?.fancy &&
          !blockStatus.blockAll &&
          !blockStatus.blockFancy &&
          detailSport?.page?.data?.t3?.length > 0 ? (
            <>
              <div className="fancy-tab-header sports-tab-panel py-1 px-3 fancy-sports-inner-tab position-relative">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 1024 1024"
                  className="mr-2"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path>
                </svg>
                <div className="w-100">
                  <Slider {...fancySetting}>
                    <div className="sports-tab-panel-item">
                      <div>
                        <h3 className={`active `}>All</h3>
                      </div>
                    </div>
                    <div className="sports-tab-panel-item">
                      <div>
                        <h3> Market</h3>
                      </div>
                    </div>
                    <div className="sports-tab-panel-item">
                      <div>
                        <h3> Bookmakers</h3>
                      </div>
                    </div>
                  </Slider>
                </div>
                <button
                  type="button"
                  className="position-absolute end-0 p-1 top-0 info-btn border-0 btn btn-primary"
                  onClick={() => setFancyInfoModal(true)}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                  </svg>
                </button>
              </div>
            </>
          ) : detailSport?.pre?.data?.t4?.length > 0 &&
            detailSport?.matchInfo?.activeStatus?.premium ? (
            <div className="sports-tab-panel py-2 px-3 fancy-sports-inner-tab position-relative">
              <div className="w-100">
                <Slider {...premiumSetting}>
                  <div className="sports-tab-panel-item">
                    <div>
                      <h3 className={`active `}>All</h3>
                    </div>
                  </div>
                  <div className="sports-tab-panel-item">
                    <div>
                      <h3> Popular</h3>
                    </div>
                  </div>
                  <div className="sports-tab-panel-item">
                    <div>
                      <h3> Match</h3>
                    </div>
                  </div>
                  <div className="sports-tab-panel-item">
                    <div>
                      <h3> Match</h3>
                    </div>
                  </div>
                </Slider>
              </div>

              <button
                type="button"
                className="position-absolute end-0 p-2 top-0 info-btn border-0 btn btn-primary"
                onClick={() => setPremiumInfoModal(true)}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                </svg>
              </button>
            </div>
          ) : (
            <></>
          )}

          {fancyPremium &&
          detailSport?.matchInfo?.activeStatus?.fancy &&
          !blockStatus.blockAll &&
          !blockStatus.blockFancy &&
          detailSport?.page?.data?.t3?.length > 0 ? (
            <div className="match-odd-table bg-white p-3 py-1 px-0 mobile-match">
              <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                <span className="text-center w-100">No</span>
                <span className="text-center w-100">Yes</span>
              </div>
              <ul className="mb-2 rounded position-relative p-0">
                {detailSport?.page?.data?.t3?.length &&
                  stableSort(
                    detailSport?.page?.data?.t3,
                    getComparator("asc", "sortPriority")
                  ).map((item: FancyInterface, i: any) => {
                    let data =
                      JSON.stringify(BET_HISTORY) !== "{}"
                        ? BET_HISTORY?.find(
                            (_: { selection: string }) =>
                              _.selection === item.nat
                          )
                        : null;

                    return (
                      <> {![1,18].includes(item?.status1) && <>
                        <li className="position-relative" key={i}>
                          <h2>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              className="svgFroFancy"
                              viewBox="0 0 1024 1024"
                              height="20"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path>
                            </svg>
                            {item.nat}
                            {item?.betProfit &&
                              cookies.get("skyTokenFront") && (
                                <span className="d-block py-1 team-red-score">
                                  <span
                                    className={` ${
                                      item.betProfit < 0
                                        ? "text-danger"
                                        : "text-green"
                                    } team_total`}
                                  >
                                    {" "}
                                    &gt; (
                                    {parseFloat(item.betProfit)?.toFixed(2)})
                                  </span>
                                </span>
                              )}
                          </h2>
                          <div className="lay-back-table">
                            <div
                              className={`${
                                clickedBet?.sId === item.sId &&
                                clickedTableData === "11"
                                  ? "lay-active"
                                  : ""
                              } lay spark-lay`}
                              onClick={() => BetClick(item, "l1", "t3")}
                            >
                              <strong> {item.l1} </strong>
                              <span className="d-block"> {item.ls1}</span>
                            </div>

                            <div
                              className={`${
                                clickedBet?.sId === item.sId &&
                                clickedTableData === "b1"
                                  ? "back-active"
                                  : ""
                              } back spark-back`}
                              onClick={() => BetClick(item, "b1", "t3")}
                            >
                              <strong> {item.b1} </strong>
                              <span className="d-block"> {item.bs1}</span>
                            </div>
                          </div>

                          {(item.status === "SUSPEND" ||
                            item.status === "BALL RUNNING") && (
                            <div className="overlay-match">
                              {item.status === "SUSPEND"
                                ? "Suspend"
                                : item.status === "BALL RUNNING"
                                ? "Ball Running"
                                : ""}
                            </div>
                          )}
                        </li>
                        {/* <div className="market-depth-sec d-flex justify-content-end py-2">
                                                <div className="d-flex align-items-center min-max ">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M214.059 377.941H168V134.059h46.059c21.382 0 32.09-25.851 16.971-40.971L144.971 7.029c-9.373-9.373-24.568-9.373-33.941 0L24.971 93.088c-15.119 15.119-4.411 40.971 16.971 40.971H88v243.882H41.941c-21.382 0-32.09 25.851-16.971 40.971l86.059 86.059c9.373 9.373 24.568 9.373 33.941 0l86.059-86.059c15.12-15.119 4.412-40.971-16.97-40.971z"></path>
                                                    </svg>
                                                    <span>min/max</span>
                                                    <strong>
                                                        {detailSport?.matchInfo?.bet_fancy_limit?.min} / {detailSport?.matchInfo?.bet_fancy_limit?.max}
                                                    </strong>
                                                </div>
                                            </div> */}
                      </> } </>
                    );
                  })}
              </ul>
            </div>
          ) : detailSport?.pre?.data?.t4?.length > 0 &&
            detailSport?.matchInfo?.activeStatus?.premium ? (
            <div className="sport-listing">
              {detailSport?.matchInfo?.activeStatus?.premium &&
                !blockStatus.blockAll &&
                !blockStatus.blockPremium &&
                detailSport?.pre?.data?.t4?.length > 0 &&
                detailSport?.pre?.data?.t4?.map(
                  (item: PreMainInterface, i: any) => {
                    return (
                      <>
                        <div className="accordion" key={i}>
                          <div className="accordion-item">
                            <h2
                              className={`${
                                !openedPre.includes(item.sortPriority) &&
                                item.sub_sb.length > 0
                                  ? "active"
                                  : ""
                              } accordion-header`}
                              onClick={() => OpenCollopsPre(item.sortPriority)}
                            >
                              <button
                                type="button"
                                aria-expanded="true"
                                className="accordion-button"
                              >
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 1024 1024"
                                  height="25"
                                  width="25"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path>
                                </svg>
                                {item.marketName}
                              </button>
                            </h2>
                            <div
                              className={`${
                                !openedPre.includes(item.sortPriority) &&
                                item.sub_sb.length > 0
                                  ? "slide-down"
                                  : "slide-up"
                              } accordion-collapse `}
                            >
                              <div className="p-0 accordion-body">
                                <div className="sports-listing-score">
                                  <div className="gx-1  row">
                                    {!openedPre.includes(item.sortPriority) &&
                                      item.sub_sb.length > 0 &&
                                      item.sub_sb.map(
                                        (subItem: PreSubInterface, j: any) => {
                                          return (
                                            <div
                                              className="position-relative col-6"
                                              key={j}
                                              onClick={() =>
                                                BetClick(
                                                  subItem,
                                                  "odds",
                                                  "t4",
                                                  item
                                                )
                                              }
                                            >
                                              <div
                                                className={`${
                                                  clickedBet?.sId ===
                                                    subItem.sId &&
                                                  clickedTableData === "odds"
                                                    ? "back-active"
                                                    : ""
                                                } text-center relative team-wise-score`}
                                              >
                                                <span className="d-block">
                                                  {subItem.nat}
                                                </span>
                                                <strong>{subItem.odds}</strong>
                                                {subItem.betProfit && (
                                                  <div className="leading-normal">
                                                    <div
                                                      className={`${
                                                        subItem.betProfit < 0
                                                          ? "text-danger"
                                                          : "text-green"
                                                      } d-block`}
                                                    >
                                                      <span
                                                        className={`${
                                                          subItem.betProfit < 0
                                                            ? "text-danger"
                                                            : "text-green"
                                                        } `}
                                                      >
                                                        <svg
                                                          stroke="currentColor"
                                                          fill="currentColor"
                                                          strokeWidth="0"
                                                          viewBox="0 0 24 24"
                                                          height="1em"
                                                          width="1em"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            fill="none"
                                                            d="M0 0h24v24H0V0z"
                                                          ></path>
                                                          <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                                                        </svg>
                                                        {clickedBet?.sId ===
                                                          subItem.sId &&
                                                        clickedTableData ===
                                                          "odds"
                                                          ? parseFloat(
                                                              subItem.betProfit
                                                            )?.toFixed(2)
                                                          : `(${parseFloat(
                                                              subItem.betProfit
                                                            )?.toFixed(2)})`}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {console.log("sportsWidget", sportsWidget)}

      {sportWidgetFlage && sportsWidget?.length ? (
        <div className="sports-widget">
          <div className="position-relative">
            <Slider {...sportsWidgetSetting}>
              {sportsWidget.map((item: any, i: any) => {
                return (
                  <>
                    <div
                      style={{ width: "100%", display: "inline-block" }}
                      onClick={() => handleNavigation(item)}
                    >
                      <ul className="widget-active m-0 sports-widget-ul">
                        <li>
                          <span className="text">
                            {" "}
                            {item.name.split("v")[0]}
                          </span>
                          <span>0</span>
                        </li>
                        <li>
                          <span className="text">
                            {" "}
                            {item.name.split("v")[1]}
                          </span>
                          <span>0</span>
                        </li>
                      </ul>
                    </div>
                  </>
                );
              })}
            </Slider>

            <div
              className="widgetCloseButton"
              onClick={() => setSportWidgetFlage(false)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
              </svg>
            </div>
          </div>
          <span className="sports-widget-bottom-text">
            {" "}
            {sportsWidget[0].type || "cricket"} in <b>24 hours </b>{" "}
          </span>
        </div>
      ) : (
        ""
      )}
        
      {loginPopup && (
        <Login OpenModal={loginPopup} setOpenModal={setLoginPopup} />
      )}

      {positonModal && (
        <div
          className={`popup-wrp runposition  fade popup-show ${
            positonModal ? "" : ""
          }`}
        >
          <div className="pop">
            <div className="pop-content">
              <div className="pop-head">
                <h5> Run Position </h5>
                <div
                  className="pop-close"
                  onClick={() => closePositonPopup()}
                ></div>
              </div>
              <div className="pop-body">
                <table className="table table-bordered w-100 fonts-1 mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }} className="text-center">
                        Run
                      </th>
                      <th style={{ width: "50%" }} className="text-left">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="position">
                    {Object.keys(positonDetails)?.map((item: any, i: any) => {
                      return (
                        <tr key={i}>
                          <td
                            className={`text-center ${
                              positonDetails[item] < 0 ? "pink-bg" : "cyan-bg"
                            }`}
                          >
                            {item}
                          </td>
                          <td
                            className={`text-left ${
                              positonDetails[item] < 0 ? "pink-bg" : "cyan-bg"
                            }`}
                          >
                            {positonDetails[item]}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="pop-footer">
                <div
                  className={`button-wrap  pb-0 d_flex d_flex_justify_end d_flex_align_center w_100`}
                >
                  <button
                    onClick={() => closePositonPopup()}
                    type="button"
                    className="btn btn-outline-dark btn-sm"
                    data-bs-dismiss="modal"
                  >
                    {" "}
                    Close{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {console.log("clickedBet", clickedTableData.length)}
      {clickedTableData.length > 0 ? (
        <BetBox
          resetClicked={resetBet}
          placeBetClicked={PlaceBat}
          clickedBet={clickedBet}
          clickedTable={clickedTable}
          clickedTableData={clickedTableData}
          setPlaceBetLoader={setPlaceBetLoader}
        />
      ) : (
        <></>
      )}

      {fancyInfoModal && (
        <FancyInfoModal handleClose={() => setFancyInfoModal(false)} />
      )}

      {premiumInfoModal && (
        <PremiumInfoModal handleClose={() => setPremiumInfoModal(false)} />
      )}

      <div className="market-depth-modal mybet">
        {mareketDept && (
          <>
            <div className="market-title">
              <h4>Market Depth</h4>
              <button
                type="button"
                onClick={() => {
                  setMareketDept(false);
                }}
                className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 15 15"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>

            <div
              className="market-depth-body"
              style={{ height: "80vh", overflow: "auto" }}
            >
              <div>
                <MarketDepthPopup depthInfo={detailSport} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MultiMarket;
