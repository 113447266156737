import React, { useEffect, useState } from 'react'
import Loader from '../Loader'
import BottomFooter from '../BottomFooter'
import { useNavigate } from 'react-router-dom'
import { postApiAdmin } from '../../service'
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN_API } from '../../common/common'
import { Logout } from '../../common/Funcation'
import Cookies from 'universal-cookie'
import moment from 'moment'

function AccountStatementNew() {
    const cookies = new Cookies()
    const isAuthenticated = useSelector((e: any) => e.isAuthenticated);
    const [didLoad, setDidLoad] = useState<boolean>(false);
    const [isLoadingMain, setIsLoadingMain] = useState(false)
    const [pageData, setPageData] = useState<any>({})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userData = useSelector((e: any) => e.userData);

    const HeaderData = useSelector((e: any) => e.Header);

    const userId = HeaderData?._id

    const DD = useSelector((e: any) => e.domainDetails);

    useEffect(() => {
        if (userData || cookies.get('skyTokenFront')) {
            getPageData()
        }
        return () => {
        }
    }, [userData])

    useEffect(() => {
        if (cookies.get('skyTokenFront')) {
            getPageData()
        }
        return () => {
        }
    }, [])


    const getPageData = async (FROM: string = '', TO: string = '', FILTER: any = { reportType: '', report: '' }, PAGE: string = '1',) => {
        setIsLoadingMain(true)
        let data: any = {
            api: ADMIN_API.MY_ACCOUNT.GET_STATEMENTS,
            value: {
                id: userId ? userId : userData?._id,
                page: PAGE ? PAGE : '1',
                limit: '10'
            },
        }
        //reportType
        //report

        if (TO !== '') { data.value.to = TO }
        if (FROM !== '') { data.value.from = FROM }
        if (FILTER.reportType !== '') {
            data.value.type = FILTER.reportType
        }

        await postApiAdmin(data).then(function (response) {
            console.log(response);
            setPageData(response.data.data)
            setIsLoadingMain(false)

        }).catch(err => {

            if (err.response.data.statusCode === 401) {
                setIsLoadingMain(false)
                Logout()
                navigate('/login')
            }
        })
    }




    return (
        <>
            <div className='full-wrap bottomfooter leagues'>
                {isLoadingMain && <div className='loader_top'> <Loader /> </div>}

                <div className="balance-label position-relative">
                    <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => navigate('/')}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                        </svg>
                    </button>
                    Account Statement
                </div>

                <div className="p-3 pt-4 inner-sidebar-content">
                    <div className="balance-sec mb-2">
                        <h5>Your Balances</h5>
                        <h6 className="fs-4"> <span>PBU</span> {HeaderData.balance} </h6>
                    </div>
                    <button type="button" className="mb-3 btn btn-primary transaction-btn" style={{ background: 'rgb(255, 200, 0)', color: 'black', border: 'none' }}>
                        International Casino Transaction
                    </button>

                    <div className='balance-deposit-sec'>


                        {pageData.userStatement && pageData.userStatement?.results?.length > 0 ? pageData.userStatement.results.map((item: any, i: any) => {
                            return (
                                <table className="rounded overflow-hidden bg-white table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            {item.credit > 0 ?
                                                <td width="50%">
                                                    Credit
                                                    <strong className="d-block text-green">{item.credit}</strong>
                                                </td>
                                                :
                                                <td width="50%">
                                                    Debits
                                                    <strong className="d-block text-danger">{item.debit}</strong>
                                                </td>
                                            }
                                            <td width="50%">
                                                Balance
                                                <strong className="d-block">{item.balance}</strong>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="50%" colSpan={4} style={{ fontSize: "14px" }}>
                                                Remark
                                                <strong className="d-block">{item.Remark ? item.Remark : '-'}</strong>
                                            </td>
                                        </tr>

                                        {/* <tr>
                                                    <td width="40%">From/To</td>
                                                    <td width="60%">{item.from?.user_name}/{item.to?.user_name}</td>
                                                </tr> */}
                                    </tbody>
                                </table>
                            )
                        }) : <h2>No Record</h2>}

                    </div>

                </div >
            </div>
            <BottomFooter />
        </>


    )
}

export default AccountStatementNew