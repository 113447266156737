import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_API } from '../common/common';
import { useSelector } from 'react-redux';
import { postApi, } from '../service';
import { Logout } from '../common/Funcation';
import moment from 'moment';
import Cookies from 'universal-cookie';

function MybetPopup({ open, handlclose }) {
  const navigate = useNavigate()
  const cookies = new Cookies()
  const [mybet, setMybet] = useState(open);
  const [activeType, setActiveType] = useState('exchange');
  const [pageData, setPageData] = useState({})
  const [uniqueIds, setUniqueIds] = useState({})
  const [selectedMatch, setSelectedMatch] = useState()
  const [openBetinfo, setOpenBetinfo] = useState(false)
  const isAuthenticated = useSelector((e) => e.isAuthenticated);

  useEffect(() => {
    if (cookies.get('skyTokenFront')) {
      // setInterval(() => {
        getPageData()
      // }, 5000);
    }

  }, [])


  const getPageData = async () => {
    let data = {
      api: USER_API.BET_LIST,
      value: {
      }
    }
    await postApi(data).then(function (response) {
      setPageData(response.data.data)
      const groupedData = response.data.data.reduce((acc, obj) => {
        const matchId = obj.matchId;
        if (!acc[matchId]) {
          acc[matchId] = [];
        }
        acc[matchId].push(obj);
        return acc;
      }, {});

      setUniqueIds(groupedData)

    }).catch(err => {
      if (err.response.data.statusCode === 401) {
        Logout()
        // navigate('/login')
      }
    })
  }



  const handleOpenMatch = (data) => {
    setOpenBetinfo(true)
    setSelectedMatch(uniqueIds[data])
  }

  const handleBetPopup = () => {
    if (isAuthenticated) {
      setMybet(true)
    }
    else {
      window.location.href = '/login'
    }
  }



  return (
    <>
      {
        mybet &&
        <>
          <div className="market-depth-modal mybet">

            {!openBetinfo ?
              <>
                <div className="market-title">
                  <h4>My Bets</h4>
                  <button type="button"
                    onClick={() => {
                      setMybet(false)
                      handlclose(false)
                      setOpenBetinfo(false)
                      setSelectedMatch('')
                    }}
                    className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary">
                    <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                    </svg>
                  </button>
                </div>

                <div className='mybet_tab' >
                  <div className={`${activeType === 'exchange' ? 'active' : ''}`} onClick={() => setActiveType('exchange')}>Exchange <span className="bg-dark text-white d-inline-block rounded" >{pageData?.length || 0}</span></div>
                  <div className={`${activeType === 'parly' ? 'active' : ''}`} onClick={() => setActiveType('parly')} > Parly < span className="bg-dark text-white d-inline-block rounded" >0</span></div>
                </div>


                <div className="market-depth-body" style={{ height: "calc(100vh - 290px)" }}>
                  <ol className="p-0 m-0">

                    {Object.keys(uniqueIds).map((item, i) => {
                      return (
                        <>
                          <li className="d-flex align-items-center justify-content-between">
                            <div>
                              <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                {uniqueIds[item].length}
                              </span>
                              <span className="master-pass first">{uniqueIds[item][0].winnerSelection[0]} vs {uniqueIds[item][0]?.winnerSelection[1]}</span>
                              <span className="master-pass">{uniqueIds[item][0]?.betType}</span>
                            </div>
                            <button type="button" className="right-angel px-2 btn btn-primary" onClick={() => handleOpenMatch(item)}>
                              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                              </svg>
                            </button>
                          </li>
                        </>
                      )
                    })}
                  </ol>
                </div>

              </>




              :

              <>
                <div className="market-title">
                  <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => setOpenBetinfo(false)}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                    </svg>
                  </button>
                  <div className='match_title_popup'>
                    <span className="">{selectedMatch[0].winnerSelection[0]} vs {selectedMatch[0].winnerSelection[1]}</span>
                    <br />
                    <span className="master-pass">{selectedMatch[0].betType}</span>
                  </div>
                  <button type="button"
                    onClick={() => {
                      handlclose(false)
                      setMybet(false)
                      setOpenBetinfo(false)
                      setSelectedMatch('')
                    }}
                    className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 btn btn-primary">
                    <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                    </svg>
                  </button>
                </div>

                <div className="market-depth-body" >
                  <div>
                    <h5 className="text-center bg-darklight mb-0 py-2 fs-5">
                      <div className="match-border-sec active">Matched <span className="bg-dark text-white p-2 d-inline-block py-1 fs-6 rounded"> {selectedMatch.length} </span></div>
                    </h5>
                    <div className="current-bets-table p-2 border-0 single-match-body">

                      {selectedMatch.map((item, i) => {
                        return (
                          <table className="rounded overflow-hidden bg-white mb-3 odds-table table table-bordered" key={i}>
                            <tbody>


                              <tr className="lay-back">
                                <td colSpan={3}> <span className={`bet-name ${item.betSide === 'back' ? 'back' : 'lay'}`}>{item.betSide} </span> {item.selection} </td>
                              </tr>
                              <tr>
                                <td>Odds. <strong className="d-block"> {item.oddsUp} </strong></td>
                                <td>Stake (PBU) <strong className="d-block"> {item.stake} </strong></td>
                                <td>Profit (PBU)<strong className="d-block"> {item.profit} </strong></td>
                              </tr>
                              <tr>
                                <td className="" colSpan={3}>
                                  <div className="d-flex justify-content-between">Ref: {item._id}<span>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</span></div>
                                </td>
                              </tr>


                            </tbody>
                          </table>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </>
            }



          </div>
          <div className="overlay" onClick={() => setMybet(false)}></div>
        </>
      }

    </>



  )
}

export default MybetPopup