
export const USER_API = {
    LOGIN: 'auth/login',
    CHANGE_PASSWORD: 'auth/changePassword',
    HOME: 'home/velki',
    GET_CASINO: 'home/getCasino',
    LEAGUE_NAME : 'sports/getLeagueName',
    GET_CHANNEL_ID : 'sports/getChannelId',
    SPORT_LISTBY_LEAGUE : 'sports/getSportListByLeague',
    IN_PLAY: 'inPlay',
    GET_SCORE_BOARD: 'inPlay/getScorboard',
    MULTI_LIST: 'multi',
    PIN: 'multi/pin',
    PLACE_BET: 'sports/placeBet',
    BET_LIST: 'sports/betList',
    SEARCH: 'sports/search',
    GET_STACK: 'getStack',
    UPDATE_STACK: 'updateStack',
    GET_BLOCK: 'sports/getBlockMatch',
    GET_PROFILE: 'auth/getProfile',
    MARKET_LIST: 'home/marketList',
    CASINO_LOGIN: 'casino/login',
    ADD_DEPOSIT: 'onlinePayment/addDeposit',
    ADD_WITHDRAW: 'onlinePayment/addWithdrawal',
    GET_METHOD: 'onlinePayment/getMethod',
    GET_WITHDRAWAL_REQUEST: 'onlinePayment/getWithdrawalRequest',
    GET_DEPOSIT_REQUEST: 'onlinePayment/getDepositRequest',
    GET_SPORTS_WIDGET:'sports/getSportListByLeagueBySportName'

}

export const ADMIN_API = {
    LOGIN: 'login',
    GET_ROLE: 'getRole',
    DOWN_LINE_LIST: 'downLineList',
    PLAYER_CREATE: 'downLineList/player/create',
    PLAYER_GET_PROFILE: 'downLineList/player/getProfile',
    PLAYER_BET_HISTORY: 'downLineList/player/betHistory',

    AGENT_CREATE: 'downLineList/agent/create',
    AGENT_UPDATE_INFO: 'downLineList/agent/updateInfo',
    PLAYER_UPDATE_INFO: 'downLineList/player/updateInfo',
    AGENT_GET_LIST: 'downLineList/agent/getList',
    BET_HISTORY: 'downLineList/player/betHistory',
    REPORT: {
        DOWN_LINE: 'report/downline',
        ACCOUNT_STATEMENT: 'report/accountStatment',
        STATEMENT_BET_VIEW: 'myAccount/statementBetView'
    },
    MY_ACCOUNT: {
        GET_PROFILE: 'myAccount/getProfile',
        GET_ACTIVITIES: 'myAccount/getActivities',
        GET_STATEMENTS: 'myAccount/getStatements',
    },
    BET_LIST: {
        LIST_LIVE: 'betList/liveList',
        LIST: 'betList/list'
    },
    RISK: 'risk',
    BANKING: {
        GET_BALANCE: 'banking/master/getBalance',
        ADD_BALANCE: 'banking/master/addBalance',
        GET_LIST: 'banking/getList',
        UPDATE: 'banking/update'
    },
    SPORTS: {
        LIST: 'sports/getList'
    },
    SETTING: {
        BANNER: {
            GET_LIST: 'setting/banner',
            GET_ONE: 'setting/banner/getOne',
            CREATE: 'setting/banner/create',
            UPDATE: 'setting/banner/update',
        },
        DASHBOARD: {
            GET_LIST: 'setting/dashboard',
            GET_ONE: 'setting/dashboard/getOne',
            CREATE: 'setting/dashboard/create',
            UPDATE: 'setting/dashboard/update',
        },
        MANAGE_CASINO: {
            GET_LIST: 'setting/manageCasino',
            CREATE: 'setting/manageCasino/create'
        },
        PREMIUM_HISTORY: {
            GET_LIST: 'setting/premiumHistory',
            LIST_OF_BET: 'setting/premiumHistory/listOfBet',
            ROLL_BACK_WINNER: 'setting/premiumHistory/rollBackWinner',
        },
        FANCY_HISTORY: {
            GET_LIST: 'setting/fancyHistory',
            LIST_OF_BET: 'setting/fancyHistory/listOfBet',
            ROLL_BACK_WINNER: 'setting/fancyHistory/rollBackWinner'
        },
        MANAGE_PREMIUM: {
            GET_LIST: 'setting/managePremium',
            LIST_OF_BET: 'setting/managePremium/listOfBet',
            DECLARE_WINNER: 'setting/managePremium/declareWinner',
        },
        MANAGE_FANCY: {
            GET_LIST: 'setting/manageFancy',
            LIST_OF_BET: 'setting/manageFancy/listOfBet',
            DECLARE_WINNER: 'setting/manageFancy/declareWinner'
        },
        MATCH_HISTORY: {
            GET_LIST: 'setting/matchHistory'
        },
        SPORT_MARKET: {
            GET_LIST: 'sports/sportMarket',
            DECLARE_WINNER: 'sports/declareWinner',
            UPDATE: 'sports/updare',
        },
        WEBSITE: {
            CREATE: 'setting/website',
            UPDATE: 'setting/website/update',
            GET_SITE: 'setting/website/getSite',
            LIST: 'setting/website/list',
            GET_DOMAIN: 'setting/website/getDomain',
        },
        FILE_UPLOAD: 'setting/fileUpload'
    }
}

export const COLOR_OPTION = [
    { backgroundColor: "#ffcc2f", color: "#222", colorSecond: "#222", label: 'Yellow', value: 'yellow', darkColor:"#8b6b05" },
    { backgroundColor: "#67f0c2", color: "#222", colorSecond: "#222", label: 'Onsen', value: 'onsen', darkColor:"#222" },
    { backgroundColor: "#291e39", color: "#FFF", colorSecond: "#FFF", label: 'Purple', value: 'purple', darkColor:"#FFF" },
    { backgroundColor: "#DE352C", color: "#FFF", colorSecond: "#FFF", label: 'Red', value: 'red', darkColor:"#FFF" },
    { backgroundColor: "#5D6699", color: "#000", colorSecond: "#000", label: 'Blue', value: 'blue', darkColor:"#000" },
    { backgroundColor: "#000000", color: "#FFF", colorSecond: "#FFF", label: 'Black', value: 'black', darkColor:"#FFF" },
    { backgroundColor: "#FF3377", color: "#FFF", colorSecond: "#FFF", label: 'Pink', value: 'pink', darkColor:"#FFF" },
    { backgroundColor: "#FFFEBB", color: "#000", colorSecond: "#000", label: 'Light Yellow', value: 'light-yellow', darkColor:"#000" },
    { backgroundColor: "#FF8C00", color: "#FFF", colorSecond: "#FFF", label: 'Dark Orange', value: 'dark-orange', darkColor:"#FFF" },
    { backgroundColor: "#FFD700", color: "#000", colorSecond: "#000", label: 'Gold', value: 'gold', darkColor:"#000" },
    { backgroundColor: "#0CFE09", color: "#313131", colorSecond: "#313131", label: 'Radioactive Green', value: 'radioactive-green', darkColor:"#313131" },
    { backgroundColor: "#708090", color: "#000", colorSecond: "#000", label: 'Slategrey', value: 'slategrey', darkColor:"#000" },
    { backgroundColor: "#0D4F8B", color: "#FFF", colorSecond: "#FFF", label: 'Indigo Dye', value: 'indigo-dye', darkColor:"#FFF" },
    { backgroundColor: "#17934e", color: "#FFF", colorSecond: "#FFF", label: 'Green', value: 'green', darkColor:"#FFF" },
    { backgroundColor: "#00A884", color: "#000", colorSecond: "#000", label: 'Persian Green', value: 'persian-green', darkColor:"#000" },
]
export const NEW_COLOR_OPTION = [
    { backgroundColor: "#ffc800", color: "#ffc800", colorSecond: "#000", label: 'Yellow', value: 'yellow', darkColor:"#8b6b05" },
    { backgroundColor: "#67f0c2", color: "#222", colorSecond: "#222", label: 'Onsen', value: 'onsen', darkColor:"#222" },
    { backgroundColor: "#291e39", color: "#FFF", colorSecond: "#FFF", label: 'Purple', value: 'purple', darkColor:"#FFF" },
    { backgroundColor: "#DE352C", color: "#FFF", colorSecond: "#FFF", label: 'Red', value: 'red', darkColor:"#FFF" },
    { backgroundColor: "#5D6699", color: "#000", colorSecond: "#000", label: 'Blue', value: 'blue', darkColor:"#000" },
    { backgroundColor: "#000000", color: "#FFF", colorSecond: "#FFF", label: 'Black', value: 'black', darkColor:"#FFF" },
    { backgroundColor: "#FF3377", color: "#FFF", colorSecond: "#FFF", label: 'Pink', value: 'pink', darkColor:"#FFF" },
    { backgroundColor: "#FFFEBB", color: "#000", colorSecond: "#000", label: 'Light Yellow', value: 'light-yellow', darkColor:"#000" },
    { backgroundColor: "#FF8C00", color: "#FFF", colorSecond: "#FFF", label: 'Dark Orange', value: 'dark-orange', darkColor:"#FFF" },
    { backgroundColor: "#FFD700", color: "#000", colorSecond: "#000", label: 'Gold', value: 'gold', darkColor:"#000" },
    { backgroundColor: "#0CFE09", color: "#313131", colorSecond: "#313131", label: 'Radioactive Green', value: 'radioactive-green', darkColor:"#313131" },
    { backgroundColor: "#708090", color: "#000", colorSecond: "#000", label: 'Slategrey', value: 'slategrey', darkColor:"#000" },
    { backgroundColor: "#0D4F8B", color: "#FFF", colorSecond: "#FFF", label: 'Indigo Dye', value: 'indigo-dye', darkColor:"#FFF" },
    { backgroundColor: "#17934e", color: "#FFF", colorSecond: "#FFF", label: 'Green', value: 'green', darkColor:"#FFF" },
    { backgroundColor: "#00A884", color: "#000", colorSecond: "#000", label: 'Persian Green', value: 'persian-green', darkColor:"#FFF" },
    // { backgroundColor: "#428139", color: "#FFF", label: 'Green', value: 'green', darkColor:"#8b6b05" },
]