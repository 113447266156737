import React, { useState } from 'react'
import Loader from '../Loader'
import BottomFooter from '../BottomFooter'
import { useNavigate } from 'react-router-dom'

function P2PtransferLog() {
    const [isLoadingMain, setIsLoadingMain] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <div className='full-wrap bottomfooter leagues'>
                {isLoadingMain ?
                    <div className='loader_top'>
                        <Loader />
                    </div>
                    :
                    <>
                        <div className="balance-label position-relative">
                            <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => navigate('/')}>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                                </svg>
                            </button>
                            P2P Transfer Log
                        </div>

                        <div className="p-3 pt-4 inner-sidebar-content">

                            No Record Found
                        </div>

                    </>
                }
            </div>
            <BottomFooter />
        </>


    )
}

export default P2PtransferLog