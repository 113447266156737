import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ADMIN_API, USER_API } from '../../common/common'
import { Logout } from '../../common/Funcation'
import { getApi, notifyError, notifyMessage, postApi } from '../../service'
import { betHistoryInterface } from './interface'
import Cookies from 'universal-cookie';
import { styleObjectGetBGasColor } from '../../common/StyleSeter'
import StakePopup from '../../components/StakePopup'
const cookies = new Cookies()
const BetBox = (props: any) => {
    const DD = useSelector((e: any) => e.domainDetails);

    const { clickedBet, clickedTableData, resetClicked, placeBetClicked, clickedTable, isMobile, betHistoryShow, setPlaceBetLoader } = props

    const BET_HISTORY = useSelector((e: any) => e.betHistory);
    const HeaderData = useSelector((e: any) => e.Header);
    const balanceData = useSelector((e: any) => e.balance);
    const [betTable, setBetTable] = useState<boolean>(true)
    const [OpenModal, setOpenModal] = useState<boolean>(false)
    const [betHistoryTable, setBetHistoryTable] = useState<boolean>(true)
    const [selecetdAmount, setSelecetdAmount] = useState<number>(0)
    const [TotalAmount, setTotalAmount] = useState<any>()
    const [pageData, setPageData] = useState<any>(BET_HISTORY)

    const [manageStack, setManageStack] = useState<any>()

    const SPORT_DETAILS = useSelector((e: any) => e.sportDetails);
    const [detailSport, setDetailSport] = useState(SPORT_DETAILS)
    useEffect(() => {

        setDetailSport(SPORT_DETAILS)
        return () => {

        }
    }, [SPORT_DETAILS])

    useEffect(() => {
        if (cookies.get('skyTokenFront')) {
            getStack(cookies.get('skyTokenFront'))
        }
        return () => {
        }
    }, [])





    const dispatch = useDispatch()

    const checkFlout = (x: any) => {
        if (typeof x == 'number' && !isNaN(x)) {
            if (Number.isInteger(x)) {
                return false
            }
            else {
                return true
            }

        }
    }

    useEffect(() => {
        if (clickedBet && clickedTableData) {
            if (clickedTable === 't4') {

                setTotalAmount(parseFloat(`${(clickedBet[clickedTableData] - 1 + (selecetdAmount === 0 ? 1 : 0)) * selecetdAmount}`).toFixed(2))
            } else if (clickedTable === 't2') {
                // betValu * betAmount / 100
                if (checkFlout(clickedBet[clickedTableData])) {
                    // setTotalAmount(parseFloat(`${(clickedBet[clickedTableData] - 1 + (selecetdAmount === 0 ? 1 : 0)) * selecetdAmount}`).toFixed(2))

                    setTotalAmount(parseFloat(`${clickedBet[clickedTableData] * (selecetdAmount) / 100}`).toFixed(2))

                } else {
                    setTotalAmount(parseFloat(`${clickedBet[clickedTableData] * (selecetdAmount) / 100}`).toFixed(2))
                }
            }
            else if (clickedTable === 't3') {
                let totalTableData = clickedTableData === 'b1' ? 'bs1' : 'ls1'
                setTotalAmount(
                    // down * betAmount/ 100
                    parseFloat(`${clickedBet[totalTableData] * (selecetdAmount) / 100}`).toFixed(2)
                )
            } else {
                setTotalAmount(parseFloat(`${(clickedBet[clickedTableData] - 1 + (selecetdAmount === 0 ? 1 : 0)) * selecetdAmount}`).toFixed(2))
            }
        }
        return () => { }
    }, [selecetdAmount, clickedTableData, clickedBet])

    useEffect(() => {
        if (cookies.get('skyTokenFront')) {
            getPageData()
        }
        return () => {

        }
    }, [])
    useEffect(() => {

        setSelecetdAmount(0)
        return () => {

        }
    }, [clickedBet])


    useEffect(() => {
        setPageData(BET_HISTORY)
        return () => { }

    }, [BET_HISTORY])



    const getPageData = async () => {
        let data = {
            api: USER_API.BET_LIST,
            value: {
            }
        }

        await postApi(data).then(function (response) {
            setPageData(response.data.data)
            dispatch({ type: 'USER_BET_HISTORY', payload: response.data.data })

        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                // navigate('/login')
            }
        })

    }

    const getTotalAmount = (AMOUNT: number) => {
        if (clickedBet && clickedTableData) {
            if (clickedTable === 't4') {
                return (parseFloat(`${(clickedBet[clickedTableData] - 1 + (AMOUNT === 0 ? 1 : 0)) * AMOUNT}`).toFixed(2))
            } else if (clickedTable === 't2') {
                // betValu * betAmount / 100
                if (checkFlout(clickedBet[clickedTableData])) {
                    // return (parseFloat(`${(clickedBet[clickedTableData] - 1 + (AMOUNT === 0 ? 1 : 0)) * AMOUNT}`).toFixed(2))
                    return (parseFloat(`${clickedBet[clickedTableData] * (AMOUNT) / 100}`).toFixed(2))

                } else {
                    return (parseFloat(`${clickedBet[clickedTableData] * (AMOUNT) / 100}`).toFixed(2))
                }
            } else if (clickedTable === 't3') {
                let totalTableData = clickedTableData === 'b1' ? 'bs1' : 'ls1'
                return (
                    // down * betAmount/ 100
                    parseFloat(`${clickedBet[totalTableData] * (AMOUNT) / 100}`).toFixed(2)
                )
            } else {
                return (parseFloat(`${(clickedBet[clickedTableData] - 1 + (AMOUNT === 0 ? 1 : 0)) * AMOUNT}`).toFixed(2))
            }
        }
    }

    const getBoxClassname = () => {
        let B = ['b1', 'b2', 'b3']
        let L = ['l1', 'l2', 'l3']
        if (clickedTable === 't4') {
            return 'GREEN'
        }
        if (B.includes(clickedTableData)) {
            return 'BACK'
        }
        if (L.includes(clickedTableData)) {
            return 'LAY'
        }
    }

    const calcSelectedAmount = async (AMOUNT: number) => {
        let B = ['b1', 'b2', 'b3']
        let L = ['l1', 'l2', 'l3']
        setSelecetdAmount(AMOUNT)
        console.log('::::::>>>', detailSport);
        let T_A = getTotalAmount(AMOUNT)
        let data = await placeBetClicked(clickedBet, T_A, AMOUNT, clickedTable, clickedTableData, true)
        dispatch({ type: 'GET_SPORTS_LIVE_CALC', payload: { calcOntime: true, calcData: data } })
    }

    const onSelectedAmountInputChange = async (e: any) => {
        const { value } = e.target
        if (e.target.validity.valid) {
            setSelecetdAmount(value)
            let T_A = getTotalAmount(value)
            let data = await placeBetClicked(clickedBet, T_A, value, clickedTable, clickedTableData, true)
            dispatch({ type: 'GET_SPORTS_LIVE_CALC', payload: { calcOntime: true, calcData: data } })
        }
    }

    const callONChangeCalc = async (value: number) => {
        let T_A = getTotalAmount(value)
        let data = await placeBetClicked(clickedBet, T_A, value, clickedTable, clickedTableData, true)
        dispatch({ type: 'GET_SPORTS_LIVE_CALC', payload: { calcOntime: true, calcData: data } })
    }

    const actionClick = (directAction: string = '', ButtonAction: string = '', isBackSpace: boolean = false) => {
        if (directAction && ButtonAction) {
            let SM = Number(selecetdAmount)
            if (ButtonAction === 'PLUS') {
                SM = SM + Number(directAction)
            }
            callONChangeCalc(SM)
            setSelecetdAmount(SM)
            return
        }
        if (directAction) {
            let SM = `${selecetdAmount}`

            if (SM.length < 7) {
                SM = SM + `${directAction}`
                callONChangeCalc(Number(SM))
                setSelecetdAmount(Number(SM))
                return
            }
            else {
                return
            }
        }
        if (ButtonAction) {
            let SM = Number(selecetdAmount)
            if (ButtonAction === 'PLUS') {
                SM = SM + 1
            } else {
                SM = SM - 1
            }
            callONChangeCalc(SM)
            setSelecetdAmount(SM)
            return
        }
        if (isBackSpace) {
            let SM = `${selecetdAmount}`
            SM = SM.slice(0, -1)
            console.log(SM);
            callONChangeCalc(Number(SM))
            setSelecetdAmount(Number(SM))
            return
        }
    }


    const placeBetConfirm = (clickedBet: any, TotalAmount: any, selecetdAmount: number, clickedTable: any, clickedTableData: any, type: string) => {

        if (type === 'CONFIRM') {
            if (HeaderData) {
                let timeout = 0
                switch (clickedTable) {
                    case 't1':
                        timeout = HeaderData.delay.odds
                        break;
                    case 't2':
                        timeout = HeaderData.delay.bookmaker
                        break;
                    case 't3':
                        timeout = HeaderData.delay.fancy
                        break;
                    case 't4':
                        timeout = HeaderData.delay.premium
                        break;
                    default:
                        break;
                }
                setPlaceBetLoader(true)
                setTimeout(() => {
                    placeBetClicked(clickedBet, TotalAmount, selecetdAmount, clickedTable, clickedTableData)

                }, timeout * 1000);
                setTimeout(() => {
                    if (timeout !== 0) {
                        // notifyMessage('bet placed with delayed of ' + timeout + 's')
                    }
                    setOpenModal(false)
                }, 700);
                // setOpenModal(false)
            } else {
                placeBetClicked(clickedBet, TotalAmount, selecetdAmount, clickedTable, clickedTableData)
            }
        } else {
            setOpenModal(true)
        }

    }

    const getStack = async (token: string) => {

        let data = {
            api: USER_API.GET_STACK,
            value: {},
            token: token ? token : undefined
        }

        await getApi(data).then(function (response) {
            let resData = response.data?.data?.stackInfo
            if (response.data?.data?.stackInfo?.stack?.length > 0) {
                setManageStack(resData)
            } else {
                resData.stack = [10, 20, 30, 40, 50, 60, 70, 80]
                setManageStack(resData)
            }

            // setOpenSetting(!OpenSetting)
            // setSearchData(response.data.data)

        }).catch(err => {
            debugger
            notifyError(err.response.data.message)
            if (err.response.data.statusCode === 401) {
                Logout()
                // navigate('/login')
            }
        })
    }




    const closePopup = () => {
        setOpenModal(false)
    }
    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => { setIsHover(true); };
    const handleMouseLeave = () => { setIsHover(false); };


    const [OpenSetting, setOpenSetting] = useState(false);

    return (
        <>


            <div className='footer-bets plays-bet'>
                <div className='player-header d-flex justify-content-between mb-3 align-items-center'>
                    <div className="d-flex align-items-center">
                        {clickedTableData.includes('l') ? <span className='bet-name lay pink-bg'>{clickedTable === 't3' ? 'yes' : 'Lay'}  </span> :
                            <span className='bet-name lay cyan-bg'>{clickedTable === 't3' ? 'No' : 'Back'} </span>}
                        <h6 className="mb-0 fs-4 fw-bold">{clickedBet?.nat}</h6>
                    </div>
                    <button className="bg-transparent border-0 p-0 fs-3" onClick={() => resetClicked()}>
                        <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                        </svg>
                    </button>
                </div>
                <div className="player-body">
                    <form className="border-0">
                        <div className="gx-2 mb-2 keyboard-div row">
                            <div className="col-6">
                                <div className="add-bet text-center">
                                    <span className="d-block">Odds</span>
                                    <div className="d-flex align-items-center">
                                        <button type="button" disabled className="btn btn-primary">-</button>
                                        <input type="text" inputMode="none" className="input-form-control" maxLength={7} disabled readOnly value={clickedBet && clickedTableData && clickedBet[clickedTableData]} />
                                        <button type="button" disabled className="btn btn-primary">+</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="add-bet text-center">
                                    <span className="d-block">Stake</span>
                                    <div className="d-flex align-items-center">
                                        <button type="button" disabled={!selecetdAmount} className="btn btn-primary" onClick={() => actionClick('', 'SUB')}>-</button>
                                        {/* <span type="number" inputMode="none" pattern="[0-9]*" className="input-form-control" autoFocus={true} maxLength={7} value={selecetdAmount ? selecetdAmount : ''} onChange={(e) => onSelectedAmountInputChange(e)} /> */}
                                        <span className="input-form-control is-focus" > {selecetdAmount ? selecetdAmount : ''}</span>
                                        <button type="button" disabled={!selecetdAmount} className="btn btn-primary" onClick={() => actionClick('', 'PLUS')}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="fix-bet-price d-flex align-items-center">
                            <div className="fix-bet-btn d-flex align-items-center w-100">
                                {
                                    manageStack?.stack?.slice(0, 4).map((item: any, i: any) => {
                                        return (
                                            <button key={i} type="button" className="bg-yellow btn btn btn-primary" onClick={() => actionClick(`${item}`, 'PLUS')}>+ {item}</button>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                <button type="button" className="bg-yellow btn btn-primary setting_icon" onClick={() => setOpenSetting(true)}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" className="fs-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M512.5 390.6c-29.9 0-57.9 11.6-79.1 32.8-21.1 21.2-32.8 49.2-32.8 79.1 0 29.9 11.7 57.9 32.8 79.1 21.2 21.1 49.2 32.8 79.1 32.8 29.9 0 57.9-11.7 79.1-32.8 21.1-21.2 32.8-49.2 32.8-79.1 0-29.9-11.7-57.9-32.8-79.1a110.96 110.96 0 0 0-79.1-32.8zm412.3 235.5l-65.4-55.9c3.1-19 4.7-38.4 4.7-57.7s-1.6-38.8-4.7-57.7l65.4-55.9a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a442.5 442.5 0 0 0-79.6-137.7l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.2 28.9c-30-24.6-63.4-44-99.6-57.5l-15.7-84.9a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52-9.4-106.8-9.4-158.8 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.3a353.44 353.44 0 0 0-98.9 57.3l-81.8-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a445.93 445.93 0 0 0-79.6 137.7l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.2 56.5c-3.1 18.8-4.6 38-4.6 57 0 19.2 1.5 38.4 4.6 57l-66 56.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.3 44.8 96.8 79.6 137.7l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.8-29.1c29.8 24.5 63 43.9 98.9 57.3l15.8 85.3a32.05 32.05 0 0 0 25.8 25.7l2.7.5a448.27 448.27 0 0 0 158.8 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-84.9c36.2-13.6 69.6-32.9 99.6-57.5l81.2 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.5-87.4 79.6-137.7l.9-2.6c4.3-12.4.6-26.3-9.5-35zm-412.3 52.2c-97.1 0-175.8-78.7-175.8-175.8s78.7-175.8 175.8-175.8 175.8 78.7 175.8 175.8-78.7 175.8-175.8 175.8z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="betting-numbers d-flex align-items-center mb-2">
                            <div className="d-flex flex-wrap bet-numeric">
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('1')} > 1 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('2')} > 2 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('3')} > 3 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('4')} > 4 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('5')} > 5 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('6')} > 6 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('7')} > 7 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('8')} > 8 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('9')} > 9 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('0')} > 0 </button>
                                <button type="button" className="btn btn btn-primary" onClick={() => actionClick('00')} > 00 </button>
                                <button type="button" className="btn btn btn-primary"> . </button>
                            </div>
                            <div className="cross-bet" onClick={() => actionClick('', '', true)}>
                                <button type="button" className="btn h-100 btn btn-primary">
                                    <img src={`data:image/svg+xml,<svg width="18" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M14.52 8.6c.07.06.1.13.1.2 0 .06-.03.13-.1.2l-.74.75c-.05.05-.12.07-.21.07-.1 0-.16-.02-.21-.07l-2.67-2.63-2.67 2.63c-.05.05-.12.07-.22.07-.09 0-.16-.02-.2-.07L6.85 9c-.07-.07-.11-.14-.11-.2 0-.07.04-.14.1-.2L9.54 6 6.82 3.4c-.14-.13-.14-.26 0-.4l.77-.75c.05-.05.12-.07.21-.07.1 0 .17.02.22.07l2.67 2.6 2.67-2.6c.05-.05.12-.07.21-.07.1 0 .16.02.21.07l.77.75c.15.14.15.27 0 .4L11.85 6l2.67 2.6zM17.54.47c.3.3.46.67.46 1.12v8.73c0 .45-.16.85-.47 1.18-.32.33-.7.49-1.15.49H5.91a2.52 2.52 0 0 1-2-.99l-.04-.03L0 6 3.87.95C4.36.32 5.04 0 5.9 0h10.47c.45 0 .83.16 1.16.48zm-.66 9.93V1.52c0-.35-.17-.52-.5-.52H5.91c-.47 0-.85.19-1.13.56L1.41 6l3.37 4.44c.3.37.68.56 1.13.56h10.47c.33 0 .5-.2.5-.59z" fill="%231E1E1E" fill-rule="evenodd" /></svg>`} alt="" />
                                </button>
                            </div>
                        </div>

                        <div className="d-flex align-items-center min-max justify-content-end mb-3">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M214.059 377.941H168V134.059h46.059c21.382 0 32.09-25.851 16.971-40.971L144.971 7.029c-9.373-9.373-24.568-9.373-33.941 0L24.971 93.088c-15.119 15.119-4.411 40.971 16.971 40.971H88v243.882H41.941c-21.382 0-32.09 25.851-16.971 40.971l86.059 86.059c9.373 9.373 24.568 9.373 33.941 0l86.059-86.059c15.12-15.119 4.412-40.971-16.97-40.971z"></path>
                            </svg>
                            <span>min/max</span>
                            <strong>1/500</strong>
                        </div>

                        <button type="button"
                            disabled={!selecetdAmount}
                            className="bg-yellow w-100 fs-5 py-2 fw-bold place-bet-button btn btn-primary"
                            onClick={() => placeBetClicked(clickedBet, TotalAmount, selecetdAmount, clickedTable, clickedTableData)}>
                            Place Bet
                        </button>

                    </form>
                </div>

                {OpenSetting && <StakePopup setOpenSetting={setOpenSetting} getStackForPerent={getStack} />}

            </div>
        </>
    )
}

export default BetBox