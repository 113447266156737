/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import BottomFooter from '../BottomFooter'
import Loader from '../Loader'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ADMIN_API } from '../../common/common'
import { Logout } from '../../common/Funcation'
import { postApiAdmin } from '../../service';
import moment from 'moment'

const whoitsforsettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // variableWidth: true,
  arrows: false,
};


function ActivityLogNew(props: any) {
  const userData = useSelector((e: any) => e.Header);
  const { userId } = props
  const navigate = useNavigate()
  const [isLoadingMain, setIsLoadingMain] = useState(false)
  const [pageData, setPageData] = useState<any>({})

  console.log("userData", userData);


  useEffect(() => {

    if (userData) {
      getPageData()
    }

    return () => {

    }
  }, [userData])


  const getPageData = async () => {

    let data = {
      api: ADMIN_API.MY_ACCOUNT.GET_ACTIVITIES,
      value: {
        id: userId ? userId : userData?._id,
        page: '1',
        limit: '10000'
      },
    }

    await postApiAdmin(data).then(function (response) {
      console.log(response);
      setPageData(response.data.data)

    }).catch(err => {

      if (err.response.data.statusCode === 401) {
        Logout()
        navigate('/login')
      }
    })

  }
  console.log("pageData", pageData);


  return (
    <>
      <div className='full-wrap bottomfooter leagues'>
        {isLoadingMain ?
          <div className='loader_top'>
            <Loader />
          </div>
          :
          <>
            <div className="balance-label position-relative">
              <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0" onClick={() => navigate('/')}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" className="fs-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                </svg>
              </button>
              Active Log
            </div>

            <div className="p-sm-3 p-2 pt-3">
              <div className="active-log-table">

                {pageData?.userActivitie && pageData?.userActivitie?.results.length > 0 ? pageData?.userActivitie?.results.map((item: any, i: any) => {
                  return (
                    <>
                      <table className="rounded overflow-hidden table table-bordered">
                        <thead>
                          <tr>
                            <th colSpan={4}>{moment(item.createdAt).format('DD/MM/YYYY, hh:mm A')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={4}>Login Status <strong className="d-block" style={{ color: 'green' }}>Login Success</strong></td>
                          </tr>
                          <tr>
                            <td width="40%">IP Address</td>
                            <td width="60%">{item.ip_address}</td>
                          </tr>
                          <tr>
                            <td width="40%">ISP</td>
                            <td width="60%">{item?.ISP}</td>
                          </tr>
                          <tr>
                            <td width="40%">City/State/Country</td>
                            <td width="60%">{item?.city}-{item?.state}-{item?.country}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )
                }) : <h2>No Record</h2>}
              </div>
            </div>
          </>
        }
      </div>
      <BottomFooter />
    </>
  )
}

export default ActivityLogNew